<template>
  <div class="product-page">
    <div class="left-text">
      <div
        class="left-text__back"
        :style="{
          height: textHeight + 'px',
        }"
      >
        <div
          class="left-text__parallax"
          :style="{
            height: 100 * offsets.length + 'vh',
            transform: parallaxTransform2,
          }"
        ></div>
      </div>
      <div class="left-text__text"></div>
    </div>
    <div
      class="parallax-bg"
      :style="{
        height: 100 * offsets.length + '%',
        transform: parallaxTransform,
      }"
    ></div>
    <div class="sections-next" @click="scrollToSection(activeSection + 1)" v-if="activeSection != offsets.length - 1" :style="{
        marginBottom: textHeight + 'px',
      }">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M0.788574 0.884466L5.50011 5.86523L10.2117 0.884466" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div class="sections-prev" @click="scrollToSection(activeSection - 1)" v-if="activeSection != 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M10.2114 6.78839L5.49989 1.80762L0.788349 6.78839" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div
      class="sections-menu"
      :style="{
        marginTop: -(textHeight - headerHeight) / 2 + 'px',
      }"
    >
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      >
      </span>
    </div>
    <div ref="packOverlay" class="cigarettes-pack-page cigarettes-pack-page-5">
      <iframe id="cigarettes-pack-page__model"
              src="https://www.vectary.com/viewer/v1/?model=c4ae7000-1329-491d-9cdc-c4e830537aa0&arAllowScaling=0&lang=ru&showPreloader=1&showPlaceholder=0&showInteractionPrompt=0&zoom=0&pan=0&rotateY=0&doubleClickToFocus=0&env=snowyforest&exposure=10&gamma=1.5"
              allowtransparency="true"
              frameborder="0"
              width="100%"
              height="100%">
      </iframe>
      <transition name="fade">
        <div class="cigarettes-pack-page__360" v-if="show360"></div>
      </transition>
    </div>
    <div
      id="fp"
      :style="{
        height: containerHeight + 'px',
      }"
    >
      <section
        class="fullpage"
        :class="{active: animateIndex == index}"
        v-for="(item, key, index) in $slots"
        :key="index"
      >
        <div>
          <transition name="slide-left">
            <div
              v-show="animateIndex == index"
              class="animate-block"
              ref="anim"
            >
              <slot :name="key"></slot>
            </div>
          </transition>
          <div v-if="nextButton.url && offsets.length == index + 1" class="_g-hidden-tablet">
            <div class="root-more _product">
              <div class="container">
                <transition name="slide-right">
                  <div v-show="animateIndex == index">
                    <router-link :to="{ path: nextButton.url }" class="button button__lg button__blue button__arrow" v-html="nextButton.text"></router-link>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import { VctrApi } from "./../vectary";
import productMixin from "../mixins/productMixin";

export default {
  name: 'ProductPage',
  mixins: [productMixin],
  methods: {
    async animatePack() {
      let state = {};
      switch (this.activeSection) {
        case 0:
          state = {
            "target": [0, 0.0054, 0],
            "position": [0.15677579898646565, 1.5699247457590104e-16, 0.2986991011086602],
            "zoom": 6.45
          };
          break;
        case 1:
          state = {
            "target": [0.00185, 0.0069, 0],
            "position": [-0.21021101647638335, 0.350700000000000005, 0.31671991503665775],
            "zoom": 10.65
          };
          break;
        case 2:
          state = {
            "target": [0.00134, 0.0059, 0],
            "position": [0.1763748760617525, -0.00514999999999998669, 0.3344285805410055],
            "zoom": 6.95
          };
          break;
        case 3:
          state = {
            "target": [0.00134, 0.0059, 0],
            "position": [0, 0.0015, 0.33734226558641245],
            "zoom": 6.95
          };
          break;
        case 4:
          state = {
            "target": [0, 0.0054, 0],
            "position": [0, 0, 0.33734226558641245],
            "zoom": 6.45
          };
          break;
      }

      // на 4 и 5 экранах открываем пачку, если она закрыта и вытаскиваем сигарету
      if (this.activeSection > 0 && this.activeSection < 4) {
        this.$refs.packOverlay.classList.add('mobile-rotate');
        this.animatePackPosition(state, 2000);
        if (!this.isUp) {
          this.isClosed = false;
          this.isUp = true;
          await this.switchPack(true);
          setTimeout(() => { this.moveCigarette(true)}, 2000);
        }
        return;
      }

      // анимация пачки
      if (!this.isClosed) {
        this.$refs.packOverlay.classList.remove('mobile-rotate');
        this.isClosed = true;
        this.isUp = false;
        await this.moveCigarette(false);
        this.animatePackPosition(state);
        setTimeout(() => {
          this.switchPack(false)}, 700
        );
      } else {
        this.animatePackPosition(state, 1500);
      }
    },
    async moveCigarette(isOpen) {
      const cigaretteObjectName = 'сигарета_аним';
      const currentPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
      const yPosition = isOpen ? 123.3020202690704 :  83.7540105655159;

      VctrApi.Utils.animate(
        500,
        "easeInOutQuad",
        async (timeFraction) => {
          const position = VctrApi.Utils.lerp(currentPosition, [currentPosition[0], yPosition, currentPosition[2]], timeFraction);


          await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, position);
        });
    },
    async switchPack(isOpen) {
      const capObjectName = 'тело_внутр';
      const xPosition = isOpen ? 0.079807946005363988 : -0.009807946005363988;

      // анимация открытия крышки
      setTimeout(async () => {
        const currentPosition = await this.vectaryApi.getPosition(capObjectName);

        VctrApi.Utils.animate(
          700,
          "easeInOutQuad",
          async (timeFraction) => {
            const position = VctrApi.Utils.lerp(currentPosition, [xPosition, currentPosition[1], currentPosition[2]], timeFraction);

            await this.vectaryApi.setPositionAbsolute(capObjectName, position);
          });
      }, isOpen ? 0 : 400)
    },
  },
};
</script>

