<template>
  <form>
    <h2 class="form__title">Регистрация</h2>
    <h3 class="form__subtitle">3 шаг. Подтверждение номера<br> мобильного телефона</h3>
    <div class="form__description">
      <p>
        Для подтверждения номера телефона необходимо сделать звонок по номеру
        <a :href="`+${phone.data.callDetails.confirmationNumber}`">
          +{{ phone.data.callDetails.confirmationNumber }}
        </a>
      </p>
    </div>
    <div class="form-row form-row_mobile-wrap">
      <div class="form__nav">
        <router-link v-if="!$store.state.currentUser" :to="{name: 'login'}" class="form__link">Войти</router-link>
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        <delete-questionnaire-btn />
      </div>
    </div>
  </form>
</template>

<script>
import api from "../services/api";

export default {
  name: "SignUpStep3",
  props: {
    phone: {}
  },
  data() {
    return {
      requestNegative: true,
    }
  },
  methods: {
    async phoneByCallCheck() {
      try {
        if (this.requestNegative) {
          const callId = await api.simplePostRequest('/api/verifyPhoneByCallCheck', { callId: this.phone.data.callDetails.callId });
          if (callId) {
            this.requestNegative = false;
            this.$emit('complete');
            this.$store.dispatch('hideErrorsBag');
          }
        }
      } catch (e) {
        this.$store.dispatch('setErrors', e);
      }
    }
  },
  async created() {
    setInterval(this.phoneByCallCheck, 5000);
  },
  beforeDestroy() {
    clearInterval(this.phoneByCallCheck);
  }
}
</script>
