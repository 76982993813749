<template>
  <div>
    <h2 class="form__title">{{ title }}</h2>
    <p class="form__description form__description_align-center" v-if="$store.state.userNonsmoking">Данный сайт предназначен только для совершеннолетних потребителей табачной продукции</p>
    <div class="form-row form-row_mobile-wrap form-row_align-center">
      <div class="form__nav">
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpStep9",
  computed: {
    title() {
      if (this.$store.state.userNonsmoking) {
        return 'Уважаемый посетитель!'
      }
      return (typeof this.$store.state.currentUser.segments.DocumentsModerationFail !== 'undefined')
        ? 'Ваш паспорт не подтверждён'
        : 'Аккаунт заблокирован';
    }
  }
}
</script>
