<template>
  <div class="product-page__about" :class="{'_alt-order': !crushMode}">
    <div class="product-page__about-left">
      <h1 class="product-page__about-title" v-html="item.title"></h1>
      <div class="product-page__about-subtitle" v-html="item.subtitle"></div>
    </div>
    <div v-if="item.text" class="product-page__about-text">
      <div v-html="item.text"></div>
    </div>
    <div class="product-page__about-benefits">
      <div v-for="(specification, j) in item.specification" :key="j" class="product-page__about-benefits-item">
        <div class="product-page__about-benefits-item-num">
          <div>{{specification.value.split(' ')[0]}} <span>{{specification.value.split(' ')[1]}}</span></div>
        </div>
        <div class="product-page__about-benefits-item-text">{{specification.text}}</div>
      </div>
    </div>
    <div v-if="item['show_button']" class="product-page__about-more">
      <a href="" class="button button__lg button__crush">Узнать больше</a>
    </div>
    <div class="product-page__disclaimer" v-html="item.disclaimer"></div>
  </div>
</template>

<script>
    export default {
        name: "ProductBenefits",
        props: {
            item: {
                type: Object,
                required: true,
            },
            crushMode: {
                type: Boolean,
                default: false
            }
        },
    }
</script>

<style lang="scss" scoped>
  @import "../../scss/base/_settings.scss";
  .product-page {
    &__about {
      &._alt-order {
        @include tablet-min {
          display: flex;
          flex-direction: column;
          .product-page__about-benefits {
            order: 1;
          }
          .product-page__about-text {
            order: 2;
          }
          .product-page__disclaimer {
            order: 3;
          }
        }
      }
    }
  }
</style>
