<template>
  <div
    class="dadata-input__wrapper"
    :class="{
      '_input-error': error,
      'text-input_filled': filled,
      'text-input_focused': focused,
    }"
  >
    <label v-if="label" class="text-input__label">
      {{ label }}
    </label>
    <input
      @input="getDadata(tmpValue, 5)"
      @focus="focus"
      @blur="blur"
      v-model="tmpValue"
      :disabled="disabled"
      class="dadata-input__input"
    />
    <div
      class="dadata-input__suggestions"
      v-if="suggestions && suggestions.length"
    >
      <div
        class="dadata-input__suggestions-item"
        v-for="(suggestion, index) in suggestions"
        @click="setValue(suggestion)"
        :key="index"
      >
        {{ suggestion.value }}
      </div>
    </div>
    <slot name="errors" />
  </div>
</template>

<script>
import api from "../services/api";

export default {
  name: "DadataCustom",
  props: {
    fromBound: { type: String },
    toBound: { type: String },
    value: { type: String },
    token: { type: String },
    error: { type: Boolean },
    query: { type: String },
    label: { type: String },
    location: { type: String },
    constraints: { type: String },
    disabled: {type: Boolean, default: false},
    kladrId: {type: String}
  },
  data() {
    return {
      tmpValue: "",
      suggestions: null,
      focused: false,
      restrictValue: true,
      kladr_id: null,
      locationChangedInside: false
    };
  },
  watch: {
    location() {
      this.locationChangedInside ? this.locationChangedInside = false : this.setValue('');
      this.getKladr();
    },
    value(val) {
      this.tmpValue = val;
    }
  },
  computed: {
    filled() {
      return !!this.tmpValue;
    },
  },
  methods: {
    async focus() {
      this.focused = true;
      await this.getDadata(this.tmpValue, 5);
    },
    blur() {
      this.focused = false;
    },
    async getKladr() {
      if (this.location) {
        try {
          const { data = {} } = await api.DADATA.getAddress({
            data: {
              query: this.location,
              count: 1,
              restrict_value: true,
            },
          });
          this.kladr_id = data.suggestions.length? data.suggestions[0].data.kladr_id: null;
        } catch (e) {
          console.log(e);
        }
      } else {
        this.kladr_id = null;
      }
    },
    async getDadata(query, count) {
      if (query) {
        let suggestions = [];
        try {
          const { data = {} } = await api.DADATA.getAddress({
            data: {
              locations: [{ kladr_id: this.kladr_id }],
              query,
              count,
              from_bound: {
                value: this.fromBound,
              },
              to_bound: {
                value: this.toBound,
              },
              restrict_value: true,
            },
          });
          suggestions = data.suggestions;
        } catch (e) {
          console.log(e);
        }
        this.suggestions = suggestions;
      } else {
        this.$emit("input", query);
      }
    },
    setValue(suggestion) {
      if (suggestion) {
        if (this.toBound == 'house') {
          this.$emit("update:constraints", suggestion.data.street_with_type);
           this.locationChangedInside = true;
          this.tmpValue = `${suggestion.data.house_type} ${suggestion.data.house}`;
        } else {
          this.tmpValue = this.fromBound == this.toBound ? suggestion.data[this.fromBound + '_with_type'] : `${suggestion.data[this.fromBound + '_with_type']? suggestion.data[this.fromBound + '_with_type'] : ''} ${suggestion.data[this.toBound  + '_with_type']? suggestion.data[this.toBound  + '_with_type']: ''}`;
        }
      } else {
        this.tmpValue = '';
      }

      this.suggestions = null;
      this.$emit("input", this.tmpValue);
    },
    onClick(e) {
      if (this.$el.contains(e.target)) {
        return;
      }
      this.suggestions = null;
    },
  },
  async mounted() {
    this.tmpValue = this.value;
    this.getKladr();
    document.addEventListener("click", this.onClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClick);
  },
};
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

.dadata-input {
  &__wrapper {
    position: relative;
  }

  &__input {
    position: relative;
    width: 100%;
    height: 54px;
    padding: 0 14px;
    border: 1px solid $input-border;
    box-sizing: border-box;
    border-radius: 5px;
    color: $brand-color;
    outline: none;

    ._input-error & {
      border-color: $error-border;
    }

    &::placeholder {
      color: $text-light;
    }
  }

  &__suggestions {
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 1px solid $input-border;
    border-top: 0;
    background: #fff;

    &-item {
      display: block;
      padding: 5px 14px;

      mark {
        background: none;
      }

      &_current {
        background: #eee;
      }
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    font-size: 11px;
    line-height: 1.4;
    color: #ff0000;
  }
}
</style>
