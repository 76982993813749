<template>
  <div class="main-nonogram">
    <div class="main-nonogram__title">Правила игры:</div>
    <div class="main-nonogram__description">
      Нонограмма, или «Японский кроссворд» — это головоломка, цель которой — правильно закрасить все клетки, чтобы
      увидеть скрытое изображение. Японский кроссворд представляет собой таблицу с пустыми ячейками, сверху и слева от
      которой имеются числа для каждой из строк и столбцов. Каждое из чисел обозначает, сколько групп из закрашенных
      подряд клеток находятся в соответствующей строке и столбце в порядке их следования. Между группами должна быть
      хотя бы одна не закрашенная клетка. Ваша задача — раскрыть скрытое изображение, следуя подсказкам по краям
      игрового поля.
    </div>
    <arrow-button
        v-if="!isStart"
        class="_center"
        :label="'Старт'"
        :is-link="false"
        @click.native.prevent="isStart = true"
    />
    <div class="main-nonogram__game" v-if="isStart">

      <div class="main-nonogram__field">
        <div class="main-nonogram__scroll-container">
          <table :class="{'disabled': isFinis}">
            <tr>
              <!--top left-->
              <th></th>

              <!--top headers-->
              <th
                  v-for="(keys, i) in fstate.keys.h"
                  :key="i"
                  :data-header-x="i"
              >
                <div class="numbers">
                  <template v-if="keys.length < numberItemsHeaderX">
                      <span
                          class="numbers__item"
                          v-for="item in numberItemsHeaderX - keys.length"
                          :key="'num-x-' + item"
                      >
                      </span>
                  </template>
                  <span
                      class="numbers__item"
                      v-for="(key, j) in keys"
                      :class="{'numbers__item_filled': checkFilledNumberColumn(i, j, key)}"
                      :key="j"
                      :data-idx-number="j"
                  >
                    {{ key }}
                  </span>
                </div>
              </th>
            </tr>
            <tr v-for="(keys, y) in fstate.keys.v" :key="y">

              <!--left headers-->
              <th :data-header-y="y">
                <div class="numbers">
                  <template v-if="keys.length < numberItemsHeaderY">
                      <span
                          class="numbers__item"
                          v-for="item in numberItemsHeaderY - keys.length"
                          :key="'num-y-' + item"
                      >
                      </span>
                  </template>
                  <span
                      class="numbers__item"
                      v-for="(key, idx) in keys"
                      :class="{'numbers__item_filled': checkFilledNumberRow(y, idx, key)}"
                      :key="idx"
                      :data-idx-number="idx"
                  >
                    {{ key }}
                  </span>
                </div>
              </th>

              <!--cell-->
              <td
                  v-for="(item, x) in dataField[y]"
                  :class="{
                      'on': item === 2,
                      'off': item === 1,
                    }"
                  :key="x"
                  :data-x="x"
                  :data-y="y"
                  @click="cellOn(x, y)"
                  @mousedown.right="cellOff(x, y)"
                  @contextmenu.prevent
                  @mouseover="cellOver(x, y)"
                  @mouseleave="cellLeave(x, y)"
              >
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="main-nonogram__controls">
        <div
            class="main-nonogram__settings"
            v-if="!srcImage"
        >
          <button
              class="main-nonogram__btn"
              @click="reset"
          >
            Сгенерировать
          </button>
          <label class="main-nonogram__input">
            Высота
            <input v-model="h" type="number" min="5" max="30">
          </label>
          <label class="main-nonogram__input">
            Ширина
            <input v-model="w" type="number" min="5" max="45">
          </label>
        </div>
        <button
            class="main-nonogram__btn"
            @click="clearField"
        >
          Очистить
        </button>
        <button
            class="main-nonogram__btn"
            @click="checkErrors(0)"
        >
          Проверить
        </button>
        <button
            v-if="isShowSolutionBtn"
            class="main-nonogram__btn"
            @click="showSolution"
        >
          Решение
        </button>
      </div>

    </div>
    <template v-if="isFinis">
      <div class="main-nonogram__title">{{ title }}</div>
      <div class="main-nonogram__description">{{ description }}</div>
    </template>
    <modal :show.sync="isShowModal" :align-center="true" :max-width="500">
      <div class="main-nonogram__title">Поздравляем!</div>
      <div class="main-nonogram__description">
        Вы разгадали кроссворд
        <br><b>{{ title }}</b>
      </div>
    </modal>
    <canvas v-if="srcImage" id="nonogram-image"></canvas>
  </div>
</template>

<script>
import ArrowButton from "../ui/ArrowButton";
import Modal from "../ui/Modal";

export default {
  components: {
    ArrowButton,
    Modal
  },
  props: {
    title: {
      type: String,
      default: 'Заголовок отсутсвует'
    },
    description: {
      type: String,
      default: 'Описание отсутствует'
    },
    srcImage: {
      type: String,
      default: ''
    },
    w: {
      type: Number,
      default: 30
    },
    h: {
      type: Number,
      default: 20
    },
  },
  name: "MainNonogram",
  data() {
    return {
      fstate: {
        field: [],
        solution: [],
        keys: {
          h: [],
          v: []
        }
      },
      isStart: false,
      isFinis: false,
      isShowModal: false,
      isSolutionOn: false,
      isShowErrors: false,
      isShowSolutionBtn: false
    }
  },
  mounted() {
    this.init();
    setTimeout(() => {
      this.isShowSolutionBtn = true;
    },1000 * 120);
  },
  computed: {
    dataField () {
      if (!this.isSolutionOn) {
        return this.fstate.field;
      } else {
        return this.fstate.solution;
      }
    },
    numberItemsHeaderX () {
      return Math.max(...this.fstate.keys.h.map(item => item.length));
    },
    numberItemsHeaderY () {
      return Math.max(...this.fstate.keys.v.map(item => item.length));
    },
    isFinisMaterial() {
      let solutionsSum = 0;
      let fieldSum = 0;

      if (this.fstate.solution.length !== 0 && this.fstate.field.length !== 0) {
        for(let i = 0; i < this.h; i++) {
          solutionsSum = solutionsSum + this.fstate.solution[i].reduce((acc, number) => {
            return number === 2 ? acc + number : acc;
          }, 0);
          fieldSum = fieldSum + this.fstate.field[i].reduce((acc, number) => {
            return number === 2 ? acc + number : acc;
          }, 0);
        }
        return fieldSum >= solutionsSum / 2;
      }

      return false;
    }
  },
  watch: {
    isFinisMaterial() {
      if (this.isFinisMaterial) {
        this.$emit('finisMaterial');
      }
    }
  },
  methods: {
    init() {
      if (this.srcImage) {
        this.createImageSolution();
      } else {
        this.reset();
      }
    },

    reset() {
      this.fstate = this.generateRandomField();
    },

    createImageSolution () {
      const canvas = document.getElementById('nonogram-image');
      const context = canvas.getContext('2d');
      let img = new Image();
      img.src = this.srcImage;

      img.onload = () => {
        canvas.width  = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);

        const cellWidth = img.width / this.w;
        const cellHeight = img.height / this.h;
        let x = 0;
        let y = 0;

        const solution = []
        for (let i = 0; i < this.h; i++) {
          x = 0;
          const arr = [];
          for (let j = 0; j < this.w; j ++) {
            const cellImageData = context.getImageData(x, y, cellWidth, cellHeight, { colorSpace: "srgb" }).data;
            arr.push(this.isFilledCell(cellImageData) ? 2 : 1);
            x = x + cellWidth;
          }
          solution.push(arr);
          y = y + cellHeight;
        }
        this.fstate.solution.push(...solution);

        this.createField();
        this.createHeadersField();
      }
    },

    createField () {
      const field = []
      for (let i = 0; i < this.h; i++) {
        const arr = []
        for (let j = 0; j < this.w; j ++) {
          arr.push(0);
        }
        field.push(arr);
      }
      this.fstate.field.push(...field);
    },

    createHeadersField () {
      const keysV = []
      for (let i = 0; i < this.h; i++) {
        const arr = [];
        let counter = 0;
        for (let j = 0; j < this.w; j ++) {
          if (this.fstate.solution[i][j] === 2) {
            counter++;
            if (j === this.w - 1) {
              arr.push(counter);
            }
          } else {
            if (counter) {
              arr.push(counter);
              counter = 0;
            }
          }
        }
        keysV.push(arr);
      }
      this.fstate.keys.v.push(...keysV);

      const keysH = []
      for (let i = 0; i < this.w; i++) {
        const arr = [];
        let counter = 0;
        for (let j = 0; j < this.h; j ++) {
          if (this.fstate.solution[j][i] === 2) {
            counter++;
            if (j === this.h - 1) {
              arr.push(counter);
            }
          } else {
            if (counter) {
              arr.push(counter);
              counter = 0;
            }
          }
        }
        keysH.push(arr);
      }
      this.fstate.keys.h.push(...keysH);
    },

    averageRGB (arrR, arrG, arrB) {
      const averageR = arrR.reduce((acc, number) => acc + number) / arrR.length;
      const averageG = arrG.reduce((acc, number) => acc + number) / arrR.length;
      const averageB = arrB.reduce((acc, number) => acc + number) / arrR.length;
      return [Math.round(averageR), Math.round(averageG), Math.round(averageB)];
    },

    isFilledCell (colorData) {
      const colorWithoutTransparency = colorData.filter((item, index) => (index + 1) % 4 !== 0);
      const averageNum = Math.round(colorWithoutTransparency.reduce((acc, number) => acc + number) / colorWithoutTransparency.length);
      return averageNum <= 255 / 2;
    },

    cellOn (x, y) {
      if (this.isSolutionOn) return false;

      if (this.fstate.field[y][x] !== 2) {
        window.vm.$set(this.fstate.field[y], x, 2);
        if (!this.isShowErrors) this.checkErrors(2000);

        this.checkCellsFull(x, y);
        this.checkVictory();
      } else {
        window.vm.$set(this.fstate.field[y], x, 0);
      }
    },

    cellOff (x, y) {
      if (this.isSolutionOn) return false;

      if (this.fstate.field[y][x] !== 1) {
        window.vm.$set(this.fstate.field[y], x, 1);
        if (!this.isShowErrors) this.checkErrors(2000);

        this.checkVictory();
      } else {
        window.vm.$set(this.fstate.field[y], x, 0);
      }
    },

    cellOver (x, y) {
      document.querySelector(`[data-header-x="${x}"]`).classList.add('highlight');
      document.querySelector(`[data-header-y="${y}"]`).classList.add('highlight');
    },

    cellLeave (x, y) {
      document.querySelector(`[data-header-x="${x}"]`).classList.remove('highlight');
      document.querySelector(`[data-header-y="${y}"]`).classList.remove('highlight');
    },

    clearField() {
      for(let i = 0; i < this.h; i++) {
        for(let j = 0; j < this.w; j++) {
          window.vm.$set(this.fstate.field[i], j, 0);
        }
      }
    },

    showSolution () {
      this.isSolutionOn = !this.isSolutionOn;
    },

    checkErrors (duration) {
      if(this.isSolutionOn) return false;

      this.isShowErrors = true;
      setTimeout(() => {
        if (!this.isShowErrors) return false;

        for (let i = 0; i < this.h; i++) {
          for (let j = 0; j < this.w; j++) {
            if (
                this.fstate.field[i][j] !== this.fstate.solution[i][j]
                && this.fstate.field[i][j] !== 0
            ) {
              const cell = document.querySelector(`[data-x="${j}"][data-y="${i}"]`);
              cell.classList.add('error');

              setTimeout(() => {
                cell.classList.remove('error');
                this.isShowErrors = false;
              },1000);
            }
          }
        }
      }, duration);
    },

    checkCellsFull (x, y) {
      let isFillColumn = true;
      for (let i = 0; i < this.h; i++) {
        if (this.fstate.solution[i][x] === 2 && this.fstate.field[i][x] !== 2) {
          isFillColumn = false;
          break;
        }
      }
      if (isFillColumn) {
        for (let i = 0; i < this.h; i++) {
          window.vm.$set(this.fstate.field[i], x, this.fstate.solution[i][x]);
        }
      }

      let isFillRow = true;
      for (let i = 0; i < this.w; i++) {
        if (this.fstate.solution[y][i] === 2 && this.fstate.field[y][i] !== 2) {
          isFillRow = false;
          break;
        }
      }
      if (isFillRow) {
        for (let i = 0; i < this.w; i++) {
          window.vm.$set(this.fstate.field[y], i, this.fstate.solution[y][i]);
        }
      }
    },

    checkFilledNumberColumn (x, idx, value) {
      let counter = 0;
      let isGroupFound = false;
      let idxFirstCellGroup;

      //find group with filled cells
      for (let i = 0; i < this.h; i++) {
        if (this.fstate.solution[i][x] === 2) {
          if (!isGroupFound) {
            counter++;
          }
          isGroupFound = true;
        } else {
          isGroupFound = false;
        }

        if (counter - 1 === idx) {
          idxFirstCellGroup = i;
          break;
        }
      }

      let isFilledCellsGroup = false;

      if (isGroupFound) {
        for (let j = 0; j < value; j++) {
          if (idxFirstCellGroup + j < this.h) {
            if (this.fstate.field[idxFirstCellGroup + j][x] === 2) {
              isFilledCellsGroup = true;
            } else {
              isFilledCellsGroup = false;
              break;
            }
          }
        }
      }

      return isFilledCellsGroup;
    },

    checkFilledNumberRow (y, idx, value) {
      let counter = 0;
      let isGroupFound = false;
      let idxFirstCellGroup;

      //find group with filled cells
      for (let i = 0; i < this.w; i++) {
        if (this.fstate.solution[y][i] === 2) {
          if (!isGroupFound) {
            counter++;
          }
          isGroupFound = true;
        } else {
          isGroupFound = false;
        }

        if (counter - 1 === idx) {
          idxFirstCellGroup = i;
          break;
        }
      }

      let isFilledCellsGroup = false;

      if (isGroupFound) {
        for (let j = 0; j < value; j++) {
          if (idxFirstCellGroup + j < this.w) {
            if (this.fstate.field[y][idxFirstCellGroup + j] === 2) {
              isFilledCellsGroup = true;
            } else {
              isFilledCellsGroup = false;
              break;
            }
          }
        }
      }

      return isFilledCellsGroup;
    },

    checkVictory () {
      if (this.isSolutionOn) return false;

      for (let i = 0; i < this.h; i++) {
        for (let j = 0; j < this.w; j++) {
          if (this.fstate.solution[i][j] !== this.fstate.field[i][j]) {
            return false;
          }
        }
      }

      this.isFinis = true;
      this.isShowModal = true;
    },

    // Random generate field
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    fillArrayColumnwise(array, col, value, rowOffset, rowCount) {
      for (let i = rowOffset; i < rowOffset + rowCount; i++) {
        array[i][col] = value;
      }
    },

    shuffle(array) {
      let counter = array.length;

      // While there are elements in the array
      while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
      }

      return array;
    },

    generateRandomField() {
      let st = {
        field: [],
        solution: [],
        keys: {
          h: [],
          v: []
        }
      };

      // Create clear field
      let mainField = st.field;
      for (let i = 0; i < this.h; i++) {
        mainField[i] = [];
        for (let j = 0; j < this.w; j++) {
          mainField[i][j] = 0;
        }
      }

      // Generate horizontal keys

      // Maximum of keys
      let maxKeys;
      // Optimal values by my personal opinion :)
      // Switched by the level of 5:
      // ex. 3 keys for 15x15, 6 keys for 25x25 etc
      switch (Math.floor(this.h / 5)) {
        case 1:
          maxKeys = 2;
          break;
        case 2:
        case 3:
          maxKeys = 3;
          break;
        case 4:
          maxKeys = 5;
          break;
        case 5:
          maxKeys = 6;
          break;
        default:
          maxKeys = Math.ceil(this.h / 4) - 1;
      }

      let hkeys = st.keys.h;
      for (let i = 0; i < this.w; i++) {
        hkeys[i] = [];
        // Number of keys in a column
        // From 1 to h/2 keys with higher expectation on lesser values
        let n = this.getRandomInt(1, maxKeys);

        // Create n keys
        let left = this.h - n + 1;
        let key;
        for (let j = 0; j < n; j++) {
          key = this.getRandomInt(1, left - n + j);
          left -= key;
          hkeys[i].push(key);
        }

        // Put them in a ramdom order
        this.shuffle(hkeys[i]);
      }

      // Add offsets to the keys
      let offsets = [];
      for (let i = 0; i < this.w; i++) {
        offsets[i] = [];

        let keys = st.keys.h[i],
            kn = keys.length;
        let left = this.h - keys.reduce((a, c) => a + c, 0);

        for (let j = 1; j < kn; j++) {
          let offset = this.getRandomInt(1, left - (kn - 1) + j);
          offsets[i].push(offset);
          left -= offset;
        }
        // Randomize offset order
        this.shuffle(offsets[i]);

        // Add first and last offsets
        let offset = this.getRandomInt(0, left);
        offsets[i].unshift(offset);
        left -= offset;

        offsets[i].push(left);
      }

      // Generate solution
      let field = st.solution;
      for (let i = 0; i < this.h; i++) {
        field[i] = [];
      }
      for (let i = 0; i < this.w; i++) {
        let keys = st.keys.h[i],
            kn = keys.length,
            offs = offsets[i];
        let offset = 0;

        for (let j = 0; j < kn; j++) {
          // Fill offset
          // offset is empty cells, value - 1
          this.fillArrayColumnwise(field, i, 1, offset, offs[j]);
          offset += offs[j];

          // Fill key
          // key points to filled cells, value - 2
          this.fillArrayColumnwise(field, i, 2, offset, keys[j]);
          offset += keys[j];
        }

        // Fill the last offset
        this.fillArrayColumnwise(field, i, 1, offset, offs[kn]);
      }

      // Get vertical keys using solution field
      let vkeys = st.keys.v;
      for (let i = 0; i < this.h; i++) {
        vkeys[i] = [];
        let length = 0;

        for (let j = 0; j < this.w; j++) {
          if (field[i][j] === 2) {
            length++;
          } else {
            if (length > 0) {
              vkeys[i].push(length);
            }
            length = 0;
          }
        }

        if (length > 0) {
          vkeys[i].push(length);
        }
      }

      return st;
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/base/include";

.main-nonogram {

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 30px;

    @include mobile {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &:not(:last-child) {
      .rules-nonogram & {
        margin-bottom: 58px;

        @include mobile {
          margin-bottom: 30px;
        }
      }
    }

    .rules-nonogram__step & {
      text-align: left;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    color: $text-light;

    @include mobile {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 50px;

      .rules-nonogram & {
        margin-bottom: 68px;
      }

      @include mobile {
        margin-bottom: 24px;

        .rules-nonogram & {
          margin-bottom: 40px;
        }
      }
    }
  }

  &__scroll-container {
    padding-left: 100px;
    padding-right: 212px;
    -ms-overflow-style: none; /* ie 10+ */
    overflow: -moz-scrollbars-none; /* ff */
    overflow-x: scroll;
    overflow-y: hidden;

    @include low-desktop {
      padding-left: 30px;
      padding-right: 60px;
    }

    @include mobile {
      padding-left: 15px;
      padding-right: 15px;
    }

    /* chrome, safari */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none !important;
      background: transparent;
    }
  }

  &__field {
    position: relative;

    .main-nonogram:not(.main-nonogram_scheme) & {
      margin-left: -100px;
      margin-right: -212px;

      @include low-desktop {
        margin-left: -30px;
        margin-right: -60px;
      }

      @include mobile {
        margin-left: -15px;
        margin-right: -15px;
      }

      &::before, &::after {
        content: '';
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        pointer-events: none;

        @include low-desktop {
          width: 30px;
        }

        @include mobile {
          width: 15px;
        }
      }

      &::before {
        left: 0;
        background: linear-gradient(90deg, $white-true, $white-true 10%, rgba($white-true,0));
      }

      &::after {
        right: 0;
        background: linear-gradient(-90deg, $white-true, $white-true 10%, rgba($white-true,0));
      }
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;
      border: 3px solid $nonogram-cell-border;
      user-select: none;
      margin: 0 auto;

      &.disabled {
        pointer-events: none;
      }

      $cell-size: 20px;
      $cell-size-large: 48px;
      $cell-size-large-mobile: 36px;

      td,
      th {
        width: $cell-size;
        min-width: $cell-size;
        height: $cell-size;
        padding: 0;
        line-height: 1.5;
        white-space: nowrap;

        .main-nonogram_scheme & {
          width: $cell-size-large;
          min-width: $cell-size-large;
          height: $cell-size-large;

          @include tablet {
            width: $cell-size-large-mobile;
            min-width: $cell-size-large-mobile;
            height: $cell-size-large-mobile;
          }
        }

        &:nth-child(5n + 1) {
          border-right: 3px solid $nonogram-cell-border;
        }
      }

      tr {
        &:first-child {
          th {
            &:first-child {
              background-image: linear-gradient(rgba($nonogram-cell-border, 0), rgba($nonogram-cell-border, 0) $cell-size - 1px, $nonogram-cell-border $cell-size - 1px),
                                linear-gradient(to right, rgba($nonogram-cell-border, 0), rgba($nonogram-cell-border, 0) $cell-size - 1px, $nonogram-cell-border $cell-size - 1px);
              background-repeat: repeat;
              background-position: 0 0;
              background-size: 100% $cell-size, $cell-size 100%;

              .main-nonogram_scheme & {
                background-image: none;
                text-align: center;
                font-size: 64px;
                font-weight: 500;
                line-height: 120%;

                @include tablet {
                  font-size: 46px;
                }
              }
            }

            &:not(:nth-child(5n + 1)) {
              border-right: 1px solid $nonogram-cell-border;
            }

            .numbers {
              flex-direction: column;

              &__item {
                height: $cell-size;

                .main-nonogram_scheme & {
                  height: $cell-size-large;

                  @include tablet {
                    height: $cell-size-large-mobile;
                  }
                }

                &:last-child {
                  height: $cell-size - 1px;

                  .main-nonogram_scheme & {
                    height: $cell-size-large - 1px;

                    @include tablet {
                      height: $cell-size-large-mobile - 1px;
                    }
                  }
                }

                &:not(:last-child) {
                  border-bottom: 1px solid $nonogram-cell-border;
                }
              }
            }
          }
        }

        &:not(:first-child) {
          th {
            border-bottom: 1px solid $nonogram-cell-border;

            .numbers {
              &__item {
                width: $cell-size;

                .main-nonogram_scheme & {
                  width: $cell-size-large;

                  @include tablet {
                    width: $cell-size-large-mobile;
                  }
                }

                &:last-child {
                  width: $cell-size - 1px;

                  .main-nonogram_scheme & {
                    width: $cell-size-large - 1px;

                    @include tablet {
                      width: $cell-size-large-mobile - 1px;
                    }
                  }
                }

                &:not(:last-child) {
                  border-right: 1px solid $nonogram-cell-border;
                }
              }
            }
          }
        }

        &:nth-child(5n + 1) {
          td,
          th {
            border-bottom: 3px solid $nonogram-cell-border;
          }
        }
      }

      th {
        background-color: $nonogram-header-bg;

        &.highlight {
          background-color: $white-true;
        }

        .numbers {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-end;

          &__item {
            &:not(:empty) {
              font-weight: 500;
              font-size: 13px;
              line-height: 1;
              background: rgba($scroll-track-color, 0.5);
              display: inline-flex;
              align-items: center;
              justify-content: center;

              .main-nonogram_scheme & {
                font-size: 31px;

                @include tablet {
                  font-size: 23px;
                }
              }
            }

            &_filled {
              color: rgba($brand-color, 0.25);
            }
          }
        }
      }

      td {
        background-color: $white-true;
        border-top: 1px solid $nonogram-cell-border;
        border-left: 1px solid $nonogram-cell-border;
        box-shadow: none;
        transition: .5s box-shadow;

        &:hover {
          .main-nonogram:not(.main-nonogram_scheme) & {
            opacity: 0.85;
            background-color: $light-gray;

            &.on {
              background-color: $brand-color;
            }
          }
        }

        &.on {
          background-color: $brand-color;
        }

        &.off {
          position: relative;
          background-color: $nonogram-header-bg;
          overflow: hidden;

          &::before, &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: $nonogram-cell-border;
            width: 150%;
            height: 1px;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }

        &.error {
          box-shadow: inset 0 0 0 10px $error-text;
        }
      }
    }
  }

  &__controls {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 35px;
    margin-bottom: 45px;

    @include mobile {
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 25px;

    @include mobile {
      margin-right: 16px;
    }
  }

  &__input {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 700;
    color: $brand-color;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    input {
      margin-left: 12px;
      text-align: center;
      padding: 1px 3px 3px;
      border: 1px solid $input-border;
      border-radius: 5px;
    }
  }

  &__btn {
    border: 0;
    background: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: $blue;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 0;
    margin-left: 25px;
    margin-right: 25px;

    @include mobile {
      margin-right: 16px;
      margin-left: 16px;
    }

    .main-nonogram__settings & {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  #nonogram-image {
    display: none;
  }
}

</style>
