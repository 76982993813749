<template>
  <div class="me-tag" v-text="text" />
</template>

<script>
export default {
  name: 'MeTag',
  props: {
    text: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
@import "../../scss/base/include";

$b: '.me-tag';

#{$b} {
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 36px;
  color: #000000;
  background-color: #ffffff;
  max-width: max-content;
  border-radius: 12px;
  padding: 5px 8px 6px;
  cursor: pointer;
}

</style>
