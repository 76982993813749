<template>
  <div
    class="text-input"
    :class="{
      'text-input_focused': focused,
      'text-input_filled': filled,
      'text-input_invalid': error,
      'text-input_date': type === 'date',
      'text-input_textarea': inputTag === 'textarea'
    }"
  >
    <label class="text-input__label" :for="id">
      {{ label }}
    </label>
    <component
      :is="inputTag"
      class="text-input__field"
      :class="appendClasses"
      :value="value"
      :type="type"
      :name="name"
      :max="max"
      :tabindex="tabindex"
      :autocomplete="autocomplete"
      :placeholder="(type === 'tel' && focused) ? '+7 (999) 999-99-99' : null"
      :rows="inputTag === 'textarea' ? rows : null"
      :disabled="disabled"
      v-mask="type === 'tel' ? '+7 (###) ###-##-##' : null"
      @focus="focus"
      @blur="blur"
      @change="emitChange"
      @input="emitChange"
    />
    <slot name="errors"/>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask';

let textInputId = 0;

const types = new Set([
  'text',
  'email',
  'password',
  'date',
  'tel',
]);

export default {
  name: "TextInput",
  directives: {
    mask: VueMaskDirective
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    validations: {
      type: Array,
    },
    id: {
      type: String,
      default: () => ('text-input-' + textInputId++),
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
      validator(val) {
        return types.has(val);
      }
    },
    autocomplete: {
      type: String
    },
    max: {
      type: String,
      default: null,
    },
    tabindex: {
      type: [String, Number],
      default: null,
    },
    appendClasses: {
      type: [String, Object]
    },
    inputTag: {
      type: String,
      default: "input"
    },
    rows: {
      type: String,
      default: "1"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    filled() {
      return !!this.value;
    },
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    emitChange(e) {
      this.$emit('input', e.target.value);
    },
  },
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";
$b: ".text-input";
$inputHeight: 54px;

#{$b} {
  position: relative;
  box-sizing: border-box;
  @include mobile {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__label {
    position: absolute;
    left: 15px;
    top: 18px;
    z-index: 1;
    font-size: 14px;
    line-height: 1;
    color: $text-light;
    pointer-events: none;
    transition: all 0.3s ease;

    .text-input_focused &,
    .text-input_filled & {
      top: 2px;
      font-size: 11px;
    }
  }

  &__field {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: $inputHeight;
    padding: 0 14px;
    border: 1px solid $input-border;
    border-radius: 5px;
    background: none;
    outline: none;
    color: $brand-color;
    font-size: 14px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    &._date {
      background-image: url('#{$abs-assets-path}/assets/i/calendar.svg');
      background-position: top 50% right 17px;
      background-repeat: no-repeat;
      &::placeholder {
        color: $brand-color;
      }
      &::-webkit-calendar-picker-indicator {
        background: none;
      }
    }

    #{$b}_invalid & {
      border-color: $error-border;
    }

    #{$b}_textarea & {
      min-height: $inputHeight;
      resize: vertical;
      max-width: 100%;
      min-width: 100%;
      max-height: 500px;
      padding-top: 18px;
      padding-bottom: 18px;
      height: auto;
    }
  }

  &__error {
    /*position: absolute;*/
    /*top: 100%;*/
    /*left: 0;*/
    /*margin: 0;*/
    font-size: 11px;
    line-height: 1.4;
    color: $error-text;
    margin-bottom: -15px;
    position: relative;
  }
}
</style>
