<template>
  <div>
    <div class="product-page__lss">
      <div class="product-page__lss-title" v-html="item.title"></div>
      <div class="product-page__lss-subtitle" v-html="item.subtitle"></div>
      <div class="product-page__lss-benefits">
        <div v-for="(peculiarity, j) in item.peculiarities" :key="j" class="product-page__lss-benefits-item">
          <div class="product-page__lss-benefits-item-img">
            <img :src="peculiarity.icon"/>
          </div>
          <div class="product-page__lss-benefits-item-text" v-html="peculiarity.text"></div>
        </div>
      </div>
      <div v-if="item.disclaimer" class="product-page__disclaimer" v-html="item.disclaimer"></div>
      <div class="product-page__lss-next">
        <router-link :to="{ path: nextButton.url }" class="button button__lg button__blue button__arrow"
                     v-html="nextButton.text"></router-link>
      </div>
    </div>
    <footer-component class="_g-visible-mobile"/>
  </div>
</template>

<script>
  import FooterComponent from '../ui/FooterComponent';

  export default {
    name: "ProductLss",
    props: {
      item: {
        type: Object,
        required: true,
      },
      nextButton: {type: Object}
    },
    components: {
      FooterComponent
    }
  }
</script>
