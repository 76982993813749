<template>
  <div
    class="me-tab"
    @click="checkCurrentTab(id, chapter.name, $event)"
    ref="tab"
  >
    <div>
      <img :src="chapter.icon" :alt="chapter.name">
    </div>
    <span class="me-tab__name">
      {{ chapter.name }}
    </span>
    <span v-if="chapter.status" class="me-tab__status">
      {{ chapter.status }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'MeTab',
  props: {
    chapter: Object,
    id: { type: Number, default: 0 },
    currentTab: { type: Number, default: 0 }
  },
  methods: {
    checkCurrentTab(id, name, event) {
      if (!event.target.closest('.disabled')) {
        this.$emit('current', id, name);
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../scss/base/include";

$b: '.me-tab';

$color-unchecked: #7176A4;

@mixin custom-breakpoint {
  @media (min-width: 1200px) and (max-width: 1373px) {
    @content;
  }
}

#{$b} {
  border: 2px solid #262B58;
  border-radius: 32px;
  width: 174px;
  height: 174px;
  padding: 22px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: $transition;

  @include custom-breakpoint {
    width: 149px;
    height: 149px;
    border-radius: 24px;
  }

  &:not(:last-child) {
    margin-right: 21px;
  }

  &__name {
    color: $color-unchecked;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    transition: $transition;

    @include custom-breakpoint {
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__status {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #D3D4DF;

    @include custom-breakpoint {
      font-size: 9px;
      line-height: 16px;
    }
  }

  img {
    transition: $transition;

    @include custom-breakpoint {
      transform: scale(0.9);
      transform-origin: 50% 50%;
    }
  }

  i {
    svg {
      path {
        transition: $transition;
        fill: $color-unchecked;
      }
    }
  }

  &.possible {
    border-color: $white-true;

    #{$b}__name {
      color: $white-true !important;
    }

    svg {
      path {
        fill: $white-true;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &.current {
    border-color: $brand-color-new;

    #{$b}__name {
      color: $white-true !important;
    }

    svg {
      path {
        fill: $white-true;
      }
    }
  }

  &.disabled {
    cursor: default;
    border-color: $color-unchecked;

    #{$b}__name {
      color: $color-unchecked !important;
    }

    svg {
      path {
        fill: $color-unchecked;
      }
    }
  }
}
</style>
