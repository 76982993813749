<template>
    <div class="main-slider portal-main__block">
      <div class="main-slider__container">
        <div class="main-slider__arrows">
          <div class="container container_portal _g-relative" v-if="slides&&slides.length > 1">
            <div class="main-slider__arrows--left" @click="prev()"></div>
            <div class="main-slider__arrows--right" @click="next()"></div>
          </div>
        </div>
        <div class="main-slider__info">
          <div class="js-slider-info swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="slide in slides" :key="slide.id" :style="{backgroundImage: `url(${slide.imgUrl})`}">
                <div class="container container-slider _g-relative">
                  <div v-if="slide.topText" class="main-slider__info--category" :style="{color: slide.topTextColor}">{{slide.topText}}</div>
                  <div v-if="slide.title" class="main-slider__info--title">{{slide.title}}</div>
                  <arrow-button v-if="slide.url" class="_white" :url="slide.url" :label="slide.linkText || 'Читать'"/>
                </div>
              </div>
            </div>
            <div class="container container_portal _g-relative">
              <div class="swiper-pagination main-slider__pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Swiper, {Autoplay, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css'
import axios from "axios";

Swiper.use([Pagination, Autoplay]);

  export default {
    name: "MainSlider",
    props: {
      sliderSection: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        sliderInfoInstance: null,
        sliderImgInstance: null,
        slides: [{
          id: 0,
          imgUrl: '',
          topTextColor: '',
          topText: '',
          title: '',
          url: '',
          linkText: '',
        }],
      }
    },
    async mounted() {
      this.slides = [];
      await this.getContent();
      await this.init();
    },
    methods: {
      async getContent() {
        this.slides = await axios.post('/api/getBanners', {section: this.sliderSection})
          .then(function (response) {
            if (response.data.status === "success") {
              return response.data.data;
            } else {
              return [];
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      async init() {
        this.sliderInfoInstance = await new Swiper('.js-slider-info', {
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          slidesPerView: 1,
          spaceBetween: 0,
          loop: this.slides.length > 1,
          watchOverflow: true,
          pagination: {
            el: '.main-slider__pagination',
            clickable: true,
          }
        });
      },
      next() {
        this.sliderInfoInstance.slideNext();
      },
      prev() {
        this.sliderInfoInstance.slidePrev();
      }
    }
  }
</script>

<style lang="scss">
  @import "../../scss/base/include";
  .main-slider {

    .container-slider {
      padding: 0 112px;

      @include low-desktop {
        padding: 0 !important;
      }
    }

    &__container {
      position: relative;
      display: flex;
    }

    &__info {
      color: $white-true;
      width: 100%;
      .swiper-slide {
        background-color: $black-true;
        padding-top: 155px;
        height: 630px;
        box-sizing: border-box;
        background-size: cover;
        @media (max-width: 1280px) {
          height: 550px;
        }
        @include low-desktop {
          padding: 155px $inner-pd-md 0;
        }
        @include tablet {
          padding: 155px $inner-pd-sm 0;
        }
        @include mobile {
          padding: 90px $inner-pd-xs 0;
          height: 482px;
        }
      }

      &--category {
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        margin-bottom: 21px;
      }

      &--title {
        font-size: 56px;
        max-width: 688px;
        line-height: 110%;
        margin-bottom: 48px;
        @media (max-width: 1280px) {
          font-size: 48px;
          margin-bottom: 40px;
        }
        @include mobile {
          font-size: 32px;
          margin-bottom: 24px;
        }
      }
    }

    &__arrows {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;

      @include mobile {
        display: none;
      }

      &--left,
      &--right {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 14px;
        height: 14px;
        transition: .3s;

        &:hover {
          border-color: $blue;
        }
      }

      &--left {
        left: 40px;
        transform: translateY(-50%) rotate(45deg);
        border-left: 2px solid $scroll-track-color;
        border-bottom: 2px solid $scroll-track-color;
        @media (max-width: 1280px) {
          right: 30px;
        }
        @include tablet {
          left: 20px;
        }
      }

      &--right {
        right: 40px;
        transform: translateY(-50%) rotate(-45deg);
        border-right: 2px solid $scroll-track-color;
        border-bottom: 2px solid $scroll-track-color;
        @media (max-width: 1280px) {
          right: 30px;
        }
        @include tablet {
          right: 20px;
        }
      }
    }

    &__pagination {
      position: absolute;
      bottom: 30px;
      z-index: 1;

      .swiper-pagination-bullet {
        cursor: pointer;
        width: 10px;
        height: 10px;
        border: 1px solid $white-true;
        background-color: transparent;

        &:not(:last-child) {
          margin-right: 24px;
        }

        &.swiper-pagination-bullet-active {
          border-color: $white-true;
          background-color: $white-true;
        }
      }
    }
  }
</style>
