<template>
  <div
      id="app"
      :class="[
          $route.name+'-page',
          {'cabinet-page' : isCabinetPages}
      ]"
  >
    <div class="header" :class="{'header_menu-opened': isMenuOpened, '_no-bg': !hideProductLinks}">
      <div class="container">
        <router-link :to="{ name: 'home' }" class="header__logo" v-if="isShowLogo">
          <img src="/frontend/assets/i/logo-zen-bay.svg" class="header__logo-image header__logo-image_blue" alt=""/>
          <img src="/frontend/assets/i/logo-zen-bay-light.svg" class="header__logo-image header__logo-image_light" alt=""/>
          <img src="/frontend/assets/i/logo-zen-bay-light.svg" class="header__logo-image header__logo-image_crush" alt=""/>
          <img src="/frontend/assets/i/logo-zen-bay.svg" class="header__logo-image header__logo-image_portal" alt=""/>
          <img src="/frontend/assets/i/logo-zen-bay-light.svg" class="header__logo-image header__logo-image_zen" alt=""/>
        </router-link>

        <div class="header__nav" v-if="isCabinetPages && isAuthenticated">
          <router-link
            v-for="link in profileLinks"
            :key="link.to"
            :to="{ name: link.to, path: link.path }"
            class="header__nav-item"
          >{{link.name}}</router-link>
        </div>
        <div class="header__nav" v-else-if="isArchivePage && isAuthenticated && !isHomePage">
          <router-link
            v-for="link in portalLinks"
            :key="link.to"
            :to="{ name: link.to }"
            class="header__nav-item"
          >{{link.name}}
          </router-link>
        </div>
        <div class="header__nav" v-else-if="isAuthenticated && !isHomePage && !isPortalPage">
          <router-link
            v-for="link in brandLinks"
            :key="link.path"
            :to="{ path: link.path }"
            class="header__nav-item"
          >{{link.name}}</router-link>
        </div>
        <div
          v-if="isAuthenticated && !isPortalPage"
          class="header__burger"
          @click="toggleMenu"
        >
          <span class="header__burger-icon" />
        </div>
        <div class="header__user-btns">
          <router-link v-if="isAuthenticated && !isProfilePage" :to="{ name: 'profile' }" class="header__profile"/>
          <a v-if="currentUser.sessid" href="#" class="header__logout" @click.prevent="logout"></a>
        </div>
      </div>
    </div>

    <template v-if="userUpdated">
      <template v-if="registerBg">
        <div class="register">
          <ajax-loader v-show="isLoading"/>

          <errors-bag v-show="showErrorsBag"/>

          <router-view />
        </div>
      </template>
      <template v-else>
        <ajax-loader v-show="isLoading"/>

        <errors-bag v-show="showErrorsBag"/>

        <router-view />
      </template>
    </template>

    <update-personal v-if="showUpdateProfile || showUpdateEmail" />

    <modal
      :show.sync="isVisibleModal"
      v-if="isAuthenticated"
      class="modal-info"
      :close="close"
    >
      <div class="modal-info__wrapper">
        <div class="modal-info__title form__title">
          Уважаемые пользователи!
        </div>
        <div class="modal-info__description">
          С&nbsp;1&nbsp;февраля 2024 года бонусная программа на&nbsp;сайте mevius.ru перестанет работать.
          <br><br>
          Все победители бонусной программы за&nbsp;2023 год получат сертификаты и&nbsp;подарки в&nbsp;соответствии с&nbsp;<a class="_link" href="/upload/docs/Terms_and_Conditions_progress.pdf"><strong>условиями программы</strong></a>.
          <br><br>
          Если Вам полагается сертификат или подарок, но&nbsp;Вы не&nbsp;получили его, сообщите нам об&nbsp;этом через <a class="_link" href="https://mevius.ru/feedback"><strong>форму обратной связи</strong></a> или по&nbsp;телефону горячей линии <a class="_tel" href="tel:88002001954">8&nbsp;800 200-19-54</a>.
        </div>
        <div class="modal-info__button">
          <button class="button__blue button__lg" @click.prevent="close">
            Закрыть
          </button>
        </div>
      </div>
    </modal>

    <cookie-law theme="banner" buttonClass="button button__md button__blue" buttonText="ОК" storageType="cookies">
      <div slot="message">
         Я соглашаюсь с тем, что владелец сайта использует файлы cookie и сервисы Google Analytics для повышения удобства работы на сайте. Оставаясь на сайте, я соглашаюсь с политикой их применения.
      </div>
    </cookie-law>

    <footer-component :class="{
      '_g-hidden-mobile': $route.name === 'products-detail'
    }"/>
  </div>
</template>

<script>
import AjaxLoader from './components/AjaxLoader';
import ErrorsBag from './components/ErrorsBag';
import FooterComponent from './components/ui/FooterComponent';
import CookieLaw from 'vue-cookie-law';
import UpdatePersonal from './components/UpdatePersonal.vue';
import Modal from './components/ui/Modal.vue';

export default {
  name: 'App',
  components: {
    AjaxLoader,
    ErrorsBag,
    FooterComponent,
    CookieLaw,
    UpdatePersonal,
    Modal
  },
  data() {
    return {
      timeInMs: 5000,
      userUpdated: false,
      showUpdateProfile: false,
      brandLinks: [
        {path: '/products/lss-original', name: 'Original'},
        {path: '/products/lss-sky-blue', name: 'Sky Blue'},
        {path: '/products/ssb-sky-blue', name: 'Sky Blue Side-Slide'},
        {path: '/products/ssb-wind-blue', name: 'Wind Blue Side-Slide'},
        // {path: '/products/compact-crush', name: 'compact crush'},
      ],
      portalLinks: [
        {to: 'archive-articles', name: 'статьи'},
        {to: 'archive-tests', name: 'тесты'},
        {to: 'archive-surveys', name: 'опросы'},
      ],
      showUpdateEmail: false,
      isVisibleModal: false,
    }
  },
  computed: {
    hideProductLinks() {
      return this.$route.name !== 'register'
        && this.$route.name !== 'login'
        && this.$route.name !== 'reset'
        && !(this.$route.name === 'feedback' && !this.isAuthenticated )
    },
    isPortalPage() {
      if (this.$route.name) {
        return (this.$route.name.indexOf('portal') !== -1);
      } else return false
    },
    isArchivePage() {
      if (this.$route.name) {
        return (this.$route.name.indexOf('archive') !== -1);
      } else return false
    },
    isCabinetPages() {
      if (this.$route.name) {
        return (this.$route.path.indexOf('cabinet') !== -1);
      } else return false
    },
    registerBg() {
      return this.$route.name === 'register'
    },
    isMenuOpened() {
      return this.$store.state.isMenuOpened;
    },
    isProfileMenuOpened() {
      return this.$store.state.isProfileMenuOpened;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    showErrorsBag() {
      if (this.$store.state.errors.find((item) => item === 'Email already used')) {
        return false;
      }
      return this.$store.state.showErrorsBag;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    logoutUrl() {
      return `/?logout=yes&sessid=${this.currentUser.sessid}`;
    },
    isBanned() {
      return this.$store.state.userBanned;
    },
    isProfilePage() {
      return /profile/i.test(this.$route.name);
    },
    isHomePage() {
      return /home/i.test(this.$route.name);
    },
    isShowLogo() {
      return this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'reset';
    },
    profileLinks() {
      const segments = this.$store.state.userSegments;
      const profileLinks = [
        {path: '/products/compact-crush', name: 'Продукты'},
        {to: 'profile', name: 'Анкетные данные'},
        {to: 'activity', name: 'Прогресс'},
        {to: 'profile-documents', name: 'Документы'},
        {to: 'profile-password', name: 'Смена пароля'},
        {to: 'profile-delete', name: 'Действия с анкетой'},
        {to: 'profile-subscribe', name: 'подписки'},
      ];

      if (!!segments.alpha || !!segments.betta) {
        profileLinks.splice(2, 0, {to: 'profile-update', name: 'Обновление данных'})
      }

      return profileLinks;
    },
    getProfileData() {
      return this.$store.state.profileData ? this.$store.state.profileData : false;
    }
  },
  watch: {
    '$route'() {
      this.$store.commit('setIsMenuOpened', false);
      this.showUpdateProfile = this.isAuthenticated
        && (this.$route.name !== 'profile-update')
        && this.$route.name !== 'profile';
      this.showUpdateEmail = this.showUpdateProfile && !this.getProfileData.email;
    }
  },
  async created() {
    const auth = await this.$store.dispatch('checkAuthentication');
    if (!auth) {
      this.logout();
    }
    this.userUpdated = true;
    this.getProfile()
  },
  methods: {
    close() {
      localStorage.setItem('infoModalHidden', true);
      this.isVisibleModal = false;
    },
    toggleMenu() {
      this.$store.commit('setIsProfileMenuOpened', false);
      this.$store.dispatch('toggleMenu');
    },
    toggleProfileMenu() {
      this.$store.commit('setIsMenuOpened', false);
      this.$store.dispatch('toggleProfileMenu');
    },
    logout() {
      this.$store.dispatch('logout', this.logoutUrl);
    },
    getProfile() {
      this.$store.dispatch('fetchProfile');
    }
  },
  mounted() {

    setTimeout(()=> {
      if (localStorage.getItem('infoModalHidden') === null) {
        this.isVisibleModal = true;
      }
    }, this.timeInMs);
  }
}
</script>
