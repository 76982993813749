<template>
  <div class="main-tests__list">
    <vue-masonry-wall :items="preparedItems" :options="optionMasonry">
      <template v-slot:default="{item}">
        <div class="main-tests__item" v-if="item">
          <img :src="item.imgUrl" class="main-tests__img">
          <div class="portal-main__category" :style="{color: item.categoryColor}" v-html="item.category"></div>
          <h3 class="main-tests__title"><a :href="item.link" v-html="item.title"></a></h3>
          <arrow-button :url="item.link" :label="item.btnText"></arrow-button>
        </div>
      </template>
    </vue-masonry-wall>
  </div>
</template>

<script>
const LIST_LENGTH_BY_BREAKPOINTS = {
  main: {
    mobile: 2,
    tablet: 2,
    low_desktop: 3,
    desktop: 6,
  },
  detail: {
    mobile: 2,
    tablet: 2,
    desktop: 3,
  }
};
import ArrowButton from "../ui/ArrowButton";
import VueMasonryWall from "vue-masonry-wall";

export default {
  props: {
    mainList: {
        type: Array,
        default: () => [
        ],
      },
      isDetail: {type: Boolean, default: false},

  },
  name: "MainTests",
  components: {ArrowButton, VueMasonryWall},
  data() {
    return {
      optionMasonry: {
        width: 384,
        padding: {
          default: 16
        },
      },
      windowWith: 0,
    }
  },
  computed: {
    preparedItems() {
      if (this.$route.name != 'portal-tests' && this.$route.name != 'archive-tests') {
        let list = this.mainList;
        const param = LIST_LENGTH_BY_BREAKPOINTS;
        const windowWith = this.windowWith;
        let count = param.detail.desktop;
        if (this.isDetail) {
          switch (true) {
            case (windowWith < 768) :
              count = param.detail.mobile;
              break;
            case (windowWith > 768 && windowWith < 1047) :
              count = param.detail.tablet;
              break;
            default:
              count = param.detail.desktop;
          }
        } else {
          switch (true) {
            case (windowWith < 768) :
              count = param.main.mobile;
              break;
            case (windowWith > 768 && windowWith < 1047) :
              count = param.main.tablet;
              break;
            case (windowWith > 1047 && windowWith < 1440) :
              count = param.main.low_desktop;
              break;
            default:
              count = param.main.desktop;
          }
        }
        return list.slice(0, count);
      }
      return this.mainList;
    },
  },
  methods: {
    handleResize() {
      this.windowWith = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss">
@import "../../scss/base/include";

.main-tests {
  &__img {
    width: 100%;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    color: $brand-color;
    margin: 0 0 32px;
    @include tablet {
      font-size: 28px;
    }
    @include mobile {
      margin-bottom: 24px;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__link {
    &_mobile {
      display: none;
    }

    font-weight: bold;
    @include mobile {
      display: none;
      &_mobile {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        border: 1px solid $blue;
        border-radius: 24px;
        padding: 15px 0;
        text-align: center;
        margin: 40px 0 0;
        color: $blue;
        text-decoration: none;
      }
    }
  }

  &__list {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: -72px;

    @include mobile {
      margin-bottom: -30px;
    }

    .masonry-item {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__item {
    padding-bottom: 72px;
    @include mobile {
      padding-bottom: 32px;
    }
  }
}
</style>
