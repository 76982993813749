<template>
  <div class="me-tabs">
    <div class="me-tabs__wrapper">
      <template v-for="(chapter, idx) in chapters">
        <me-tab
          :key="idx"
          :chapter="chapter"
          :id="idx"
          @current="checkCurrentTab"
          :class="[
            selected === idx || chapter.active && allPossibles ? 'current'
            : chapter.active ? 'possible'
            : 'disabled'
          ]"
        />
      </template>
    </div>
  </div>
</template>

<script>
import MeTab from "./MeTab";

export default {
  name: 'MeTabs',
  components: {
    MeTab
  },
  props: {
    chapters: Array,
    allPossibles: { type: Boolean, default: false },
    selected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    checkCurrentTab(id, name) {
      this.$emit('theme', id, name)
    }
  },
}
</script>

<style lang="scss">
@import "../../scss/base/include";

$b: '.me-tabs';

#{$b} {
  display: flex;
  justify-content: space-around;

  @include low-desktop {
    margin-bottom: 10px;
    justify-content: center;
    -ms-overflow-style: none; /* ie 10+ */
    overflow: -moz-scrollbars-none; /* ff */
    overflow-x: scroll;
    overflow-y: hidden;

    /* chrome, safari */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none !important;
      background: transparent;
    }
  }

  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }

  &__wrapper {
    display: flex;
    width: max-content;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

