<template>
  <div class="main-articles__list">
    <div v-for="(item, i) in mainList" :key="i" class="main-articles__item--wrapper">
      <div class="main-articles__item">
        <div :style="{backgroundImage: `url(${item.imgUrl})`}" class="main-articles__img"></div>
        <div class="portal-main__category" v-html="item.category" :style="{color: item.categoryColor}"></div>
        <h3 class="main-articles__title" ><a :href="item.link" v-html="item.title"></a></h3>
        <div class="main-articles__text" v-html="item.text"></div>
        <arrow-button :url="item.link"></arrow-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowButton from "../ui/ArrowButton";

export default {
  name: "MainArticles",
  components: {ArrowButton},
  props: {
    mainList: {
      type: Array,
      default: () => [],
    }
  },
}
</script>

<style lang="scss">
@import "../../scss/base/include";

.main-articles {
  &__item {
    &--wrapper {
      width: 488px;
      @include low-desktop {
        width: 38%;
      }
      @include mobile {
        width: 100%;
      }
      margin-bottom: 70px;
      @include tablet {
        margin-bottom: 50px;
      }

      &:nth-child(odd) {
        margin-right: 138px;
        @include low-desktop {
          margin-right: 10%;
        }
        @include mobile {
          margin-right: 0;
        }
      }


      &:nth-child(3) {
        margin-left: 100px;
        @include low-desktop {
          margin-left: 7%;
        }
        @include mobile {
          margin-left: 0;
        }
      }
      &:nth-last-child(2),
      &:last-child {
        margin-bottom: 0;
      }


      &:not(:last-child) {
        @include mobile {
          margin-bottom: 32px;
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    color: $brand-color;
    margin: 0 0 18px;
    @include mobile {
      font-size: 28px;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__img {
    width: 100%;
    height: 384px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    @include low-desktop {
      height: 27.4vw;
    }
    @include mobile {
      height: 71.4vw;
      margin-bottom: 24px;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    color: $text-gray;
    margin-bottom: 32px;
    @include mobile {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }
}


</style>
