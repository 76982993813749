<template>
  <div class="main-articles" :class="{'portal-main__block':type!='surveys'}" v-if="mainList.length">
    <div class="container container_portal _g-relative" style="position:relative;">
      <div class="portal-main__upper-title">
        <h2 v-if="title">{{ title }}</h2>
        <router-link v-if="$route.name!==`archive-${type}`" :to="{ name: `archive-${type}` }" class="main-tests__link">все {{ title }}</router-link>
      </div>
      <main-articles v-if="type=='articles'" :main-list="mainList"/>
      <main-tests v-if="type=='tests'" :main-list="mainList" :isDetail="true"/>
      <main-survey v-if="type=='surveys'" :main-list="mainList"/>
      <router-link  v-if="$route.name!==`archive-${type}`" :to="{ name: `archive-${type}` }" class="main-tests__link main-tests__link_mobile">все {{ title }}</router-link>
      <div class="portal__pagination" v-else>
        <paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="1"
          :prev-text="`<div></div>`"
          :next-text="`<div></div>`"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :click-handler="scrollBack"
          v-if="this.mainList.length > 0"
        >
        </paginate>
      </div>
    </div>

  </div>
</template>
<script>
import api from "../../services/api";

export default {
  name: "PortalList",
  props: {
    notId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    archive: {type: Boolean, default: false},
    viewItems: {type: Number},
    isDetail: {type: Boolean, default: false},
  },
  data() {
    return {
      page: 1,
      pageSize: 4,
      pageCount: 0,
      mainList: [],
      routes: {
        'articles': '/api/getArticles',
        'tests': '/api/getTests',
        'surveys': '/api/surveys'
      }
    }
  },
  async mounted() {
    await this.getItems(1)

  },
  methods: {
    async getItems(page) {
      const data = await api.fetchPortalListItems(this.routes[this.type], {page, notId: this.notId, archive: this.archive});
      this.mainList = data.items || [];
      this.page = data.page;
      this.pageSize = data.pageSize;
      this.pageCount = data.pageCount;
      if (this.viewItems) {
        this.mainList = this.mainList.slice(0,this.viewItems)
      }
    },
    scrollBack(page) {
      this.getItems(page);
      setTimeout(() => {
        document.querySelector('.portal-main__upper-title').scrollIntoView(
          {
            behavior: 'smooth',
            block: 'start'
          }
        );
      }, 0)
    }
  }
}
</script>
