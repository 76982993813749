<template>
  <div class="update-personal-data">
    <h2 class="update-personal-data__title form__title" v-html="title" />
    <div class="update-personal-data__body">
      <div class="update-personal-data__steps">
        <ol class="update-personal-data__steps-list">
          <li
            v-for="(step, i) in steps"
            :key="`update-personal-data__steps-item-${i}`"
            class="update-personal-data__steps-item"
            v-html="step"
          />
        </ol>
      </div>
      <div v-if="type === 'email'" class="update-personal-data__field">
        <text-input
          label="Ваш адрес электронной почты"
          v-model="email"
          name="email"
          :error="!$v.email.required && $v.email.$error"
        />
        <div v-if="!$v.email.required && $v.email.$error" class="update-personal-data__field-error">
          Обязательное поле
        </div>
      </div>
    </div>
    <div class="update-personal-data__note" v-html="info" />
    <button
      class="update-personal-data__button button__blue button__lg"
      @click="emitClick"
    >
      {{ btnText }}
    </button>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    steps: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'segment', // segment || email
    },
    btnText: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      email: '',
    }
  },
  validations: {
    email: { required, email }
  },
  methods: {
    emitClick() {
      if (this.type === 'email') {
        this.$v.$touch();

        if ((!this.$v.$pending && !this.$v.$invalid)) {
          this.$emit('click', this.email)
        }

        return
      }
      this.$emit('click')
    },
  }
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

$b: '.update-personal-data';

#{$b} {

  &__title {
    max-width: 320px;
    margin: 0 auto 29px;
  }
  &__body {
    padding: 0 0 37px;
    margin: 0 0 16px;
    border-bottom: 1px solid #E8EBF1;
  }
  &__steps {
    
    ol {
      list-style: none;
      counter-reset: step;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 140%;
      @include mobile {
        font-size: 14px;
      }
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 19px;
        }
        &::before {
          counter-increment: step;
          content: counter(step) ".";
          display: flex;
          align-items: center;
          width: 41px;
          min-width: 41px;
          margin-right: 15px;
          height: 41px;
          justify-content: center;
          border: 1px solid #3A599C;
          border-radius: 50%;
          padding-left: 4px;
          padding-bottom: 2px;
          box-sizing: border-box;
          @include mobile {
            width: 35px;
            min-width: 35px;
            margin-right: 13px;
            height: 35px;
            padding-left: 3px;
          }
        }
      }
    }
  }
  &__input {
    margin-bottom: 37px;
  }
  &__note {
    font-size: 12px;
    line-height: 140%;
    color: #7A8BB2;
    margin-bottom: 30px;
  }
  &__button {
    text-transform: uppercase;
    width: 246px;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    cursor: pointer;

    @include mobile {
      width: 100%;
    }
  }

  &__field {
    margin-top: 24px;
    
    &-error {
      color: $error-text;
    }
  }
}
</style>