<template>
  <div>
    <h2 class="form__title">Письмо отправлено на</h2>
    <h3 class="form__subtitle">{{ email }}</h3>
    <div class="form__description form__description_align-center">
      <p>Для подтверждения электронной почты перейдите по ссылке из письма</p>
    </div>
    <div class="form__description form__description_align-center">
      <delete-questionnaire-btn />
    </div>
    <div class="form__description form__description_align-center">
      <a href="#" class="form__link" @click.prevent="sendAgain">Отправить электронное письмо повторно</a>
    </div>
    <div class="form__description_align-center">
      <a href="#" class="form__link" @click.prevent="$emit('back')">Изменить электронную почту</a>
    </div>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  name: "SignUpStep5",
  props: {
    email: {type: String, required: true}
  },
  methods: {
    async sendAgain() {
      this.$store.dispatch("showLoader");
      try {
        await api.simplePostSuccess('/api/sendEmailConfirmationCode');
      } catch (e) {
        this.$store.dispatch("setErrors", e);
      } finally {
        this.$store.dispatch("hideLoader");
      }
    }
  }
}
</script>
