<template>
  <div class="update-personal">
    <modal :show.sync="showModal" v-if="segment">
      <div class="update-personal__title form__title">Дарим подарок<br>за обновление анкеты</div>
      <div class="update-personal__steps">
        <ol>
          <li>Исправьте ошибку в&nbsp;Вашей анкете в&nbsp;строчках &laquo;Ф.И.О.&raquo; и&nbsp;&laquo;Дата рождения&raquo;,</li>
          <li v-if="segment === 'beta'">Загрузите фото паспорта,</li>
          <li>Получите подарок от команды ZenSpace.*</li>
        </ol>
      </div>
      <div class="update-personal__note">*Вручение подарка происходит в&nbsp;течении 3&nbsp;дней с&nbsp;момента подтверждения указанных Вами данных. Вознаграждение можно получить только один раз</div>
      <router-link :to="{ name: 'profile-update' }" class="button__blue button__lg update-personal__button">Исправить ошибку</router-link>
    </modal>
  </div>
</template>

<script>
import Modal from './ui/Modal.vue';
import { mapState, mapActions } from 'vuex';

const MODAL_SHOWED_KEY = 'PersonalInfoModalShowed';

export default {
  components: { Modal },
  name: "UpdatePersonalInfo",
  data() {
    return {
      timeInMs: 5000,
      timeout: null,
      showModal: false,
      segments: ['alpha', 'beta']
    };
  },
  methods: {
    ...mapActions({
      fetchUserSegments: 'fetchUserSegments'
    }),
    getModalShowedDuringSession() {
      return sessionStorage.getItem(MODAL_SHOWED_KEY);
    },
    startTimeout() {
      this.timeout = setTimeout(() => {
        this.showModal = true;
        sessionStorage.setItem(MODAL_SHOWED_KEY, true);
        this.removeMouseEventHandlers();
      }, this.timeInMs);
    },
    restartTimeout() {
      clearTimeout(this.timeout);
      this.startTimeout();
    },
    addMouseEventHandlers() {
      document.addEventListener('mousemove', this.restartTimeout);
      document.addEventListener('keypress', this.restartTimeout);
    },
    removeMouseEventHandlers() {
      document.removeEventListener('mousemove', this.restartTimeout);
      document.removeEventListener('keypress', this.restartTimeout);
    },
  },
  computed: {
    ...mapState({
      segment: state => state.userSegments.alpha? 'alpha': state.userSegments.beta ? 'beta' : null,
      idxchechbhfullname: state => state.profileData?.customFields.idxchechbhfullname
    }),

  },
  async mounted() {
    const modalShowedDuringSession = this.getModalShowedDuringSession();

    if (!modalShowedDuringSession && !this.idxchechbhfullname) {
      await this.fetchUserSegments();
      this.addMouseEventHandlers()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.removeMouseEventHandlers();
  }
}
</script>
<style lang="scss">
@import "../scss/base/_settings.scss";
.update-personal {
  &__title {
    max-width: 320px;
    margin: 0 auto 29px;
  }
  &__steps {
    padding: 0 0 37px;
    margin: 0 0 16px;
    border-bottom: 1px solid #E8EBF1;
    ol {
      list-style: none;
      counter-reset: step;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 140%;
      @include mobile {
        font-size: 14px;
      }
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 19px;
        }
        &::before {
          counter-increment: step;
          content: counter(step) ".";
          display: flex;
          align-items: center;
          width: 41px;
          min-width: 41px;
          margin-right: 15px;
          height: 41px;
          justify-content: center;
          border: 1px solid #3A599C;
          border-radius: 50%;
          padding-left: 4px;
          padding-bottom: 2px;
          box-sizing: border-box;
          @include mobile {
            width: 35px;
            min-width: 35px;
            margin-right: 13px;
            height: 35px;
            padding-left: 3px;
          }
        }
      }
    }
  }
  &__note {
    font-size: 12px;
    line-height: 140%;
    color: #7A8BB2;
    margin-bottom: 30px;
  }
  &__button {
    text-transform: uppercase;
    width: 246px;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    @include mobile {
      width: 100%;
    }
  }
  .modal {
    max-width: 657px;
    right: 0;
    position: relative;
    top: auto !important;
    &__body {
      max-width: 405px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
</style>
