<template>
  <div class="errors-bag">
    <button class="errors-bag__close" type="button" @click="close">&times;</button>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="errors-bag__item"
      v-html="error"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ErrorsBag",
  computed: {
    errors() {
      return this.$store.state.errors;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('hideErrorsBag');
    }
  }
}
</script>

<style lang="scss" scoped>
.errors-bag {
  position: fixed;
  z-index: 10050;
  top: 0;
  left: 50%;
  background: #fff;
  color: #f00;
  width: 300px;
  margin-left: -150px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px #0003;

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    outline: none;
    border: none;
    background: none;
    font-size: 20px;
    color: #f00;

    &:hover {
      color: #000;
    }
  }

  &__item {
    padding: 15px 30px;
    word-break: break-word;
  }
}
</style>
