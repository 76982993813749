<template>
  <div class="update-personal">
    <modal :show.sync="isVisible" v-if="false">
      <div
        v-for="(item, i) in blocks"
        :key="`update-personal-item-${i}`"
      >
        <UpdatePersonalData
          v-if="item.type === currentType"
          :title="item.title"
          :steps="item.steps"
          :type="item.type"
          :btnText="item.btnText"
          :info="item.info"
          @click="item.clickHandler"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from './ui/Modal.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: { Modal },
  name: "UpdatePersonal",
  data() {
    return {
      timeInMs: 5000,
      timeout: null,
      isVisible: true,
      segments: ['alpha', 'beta'],
      typeBlockEnum: {
        SEGMENT: 'segment',
        EMAIL: 'email',
      },
      currentType: null,
      finishActions: {
        btnText: "Закрыть",
      }
    }
  },
  computed: {
    segmentSteps() {
      return this.segment === 'beta' ? this.segmentStepsBeta : this.segmentStepsAlpha
    },
    segmentStepsAlpha() {
      return [
        'Исправьте ошибку в&nbsp;Вашей анкете в&nbsp;строчках &laquo;Ф.И.О.&raquo; и&nbsp;&laquo;Дата рождения&raquo;,',
        'Загрузите фото паспорта,',
        'Получите подарок от команды ZenSpace.*'
      ]
    },
    segmentStepsBeta() {
      return [
        'Исправьте ошибку в&nbsp;Вашей анкете в&nbsp;строчках &laquo;Ф.И.О.&raquo; и&nbsp;&laquo;Дата рождения&raquo;,',
        'Получите подарок от команды ZenSpace.*'
      ]
    },
    blocks() {
      return {
        segment: {
          type: this.typeBlockEnum.SEGMENT,
          title: 'Дарим подарок<br>за обновление анкеты',
          steps: this.segmentSteps,
          btnText: 'исправить ошибку',
          info: `*Вручение подарка происходит в&nbsp;течении 3&nbsp;дней с&nbsp;момента подтверждения указанных Вами данных. Вознаграждение можно получить только один раз`,
          clickHandler: this.handlerSegment,
          MODAL_SHOWED_KEY: 'PersonalInfoModalShowed'
        },
        email: {
          type: this.typeBlockEnum.EMAIL,
          title: 'Дарим подарок<br>за обновление анкеты',
          steps: [
            'Укажите свой адрес электронной почты',
            'Получите подарок от команды «Бухты Дзена»',
          ],
          btnText: 'Подтвердить',
          info: `*Вручение подарка происходит в&nbsp;течении 3&nbsp;дней с&nbsp;момента подтверждения указанных Вами данных. Вознаграждение можно получить только один раз`,
          clickHandler: this.handlerEmail,
          MODAL_SHOWED_KEY: 'PersonalEmailModalShowed'
        }
      }
    },
    ...mapState({
      segment: state => state.userSegments.alpha? 'alpha': state.userSegments.beta ? 'beta' : null,
      idxchechbhfullname: state => state.profileData?.customFields.idxchechbhfullname,
      isntExistsEmail: state => !!(state.profileData && !state.profileData.email)
    }),
  },
  methods: {
    handlerEmail(email) {
      this.$router.push({name: 'profile', params: {email}})
    },
    handlerSegment() {
      this.$router.push({name: 'profile-update'})
    },
    ...mapActions({
      fetchUserSegments: 'fetchUserSegments'
    }),
    getModalShowedDuringSession(type = this.typeBlockEnum.SEGMENT) {
      return sessionStorage.getItem(this.blocks[type].MODAL_SHOWED_KEY);
    },
    startTimeout() {
      this.timeout = setTimeout(() => {
        this.isVisible = true;
        sessionStorage.setItem(this.blocks[this.currentType].MODAL_SHOWED_KEY, true);
        this.removeMouseEventHandlers();
      }, this.timeInMs);
    },
    restartTimeout() {
      clearTimeout(this.timeout);
      this.startTimeout();
    },
    addMouseEventHandlers() {
      document.addEventListener('mousemove', this.restartTimeout);
      document.addEventListener('keypress', this.restartTimeout);
    },
    removeMouseEventHandlers() {
      document.removeEventListener('mousemove', this.restartTimeout);
      document.removeEventListener('keypress', this.restartTimeout);
    },
    removeEventsHelper(type) {
      const savedType = this.currentType
      this.currentType = type
      this.removeMouseEventHandlers()
      this.currentType = savedType
    },
    async checkState() {
      const blocksFactory = {
        email: {
          isShow: !this.getModalShowedDuringSession(this.typeBlockEnum.EMAIL) && this.isntExistsEmail,
          type: this.typeBlockEnum.EMAIL,
        },
        segment: {
          isShow: !this.getModalShowedDuringSession(this.typeBlockEnum.SEGMENT) && !this.idxchechbhfullname,
          type: this.typeBlockEnum.SEGMENT
        }
      }

      for (let i in blocksFactory) {
        const block = blocksFactory[i]

        if (block.isShow) {
          this.currentType = block.type
          this.addMouseEventHandlers();
          break
        } else {
          this.removeEventsHelper(block.type)
        }
      }
    }
  },
  watch: {
    '$route'() {
      this.checkState()
    }
  },
  async created() {
    await this.fetchUserSegments();
    this.checkState()
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.removeMouseEventHandlers();
  },
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

$b: '.update-personal';

#{$b} {
  color: $brand-color;
  .modal {
    max-width: 657px;
    right: 0;
    position: relative;
    top: auto !important;
    &__body {
      max-width: 405px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
</style>
