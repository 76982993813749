<template>
  <div class="dadata-input__wrapper" :class="{'_input-error': error, 'text-input_filled': filled}">
    <label v-if="label && profilePage" class="text-input__label">
      {{ label }}
    </label>
    <vue-dadata
      default-class="dadata-input"
      :token="token"
      :placeholder="placeholder"
      :on-change="onSelectCitySuggestion"
      :query="query"
      :autoload="profilePage"
      :fromBound="fromBound"
      :toBound="toBound"
    />
    <slot name="errors"/>
  </div>
</template>

<script>
import VueDadata from 'vue-dadata';

export default {
  name: 'DadataInput',
  components: {
    VueDadata,
  },
  props: {
    fromBound: {type: String},
    toBound: {type: String},
    value: {type: String},
    token: {type: String},
    placeholder: {type: String},
    error: {type: Boolean},
    query: {type: String},
    profilePage: {type: Boolean, default: false},
    label: {type: String},
  },
  computed: {
    filled() {
      return !!this.value;
    },
  },
  methods: {
    onSelectCitySuggestion(suggestion) {
      if (this.profilePage) {
        this.$emit('addressInput', suggestion.data);
      } else {
        this.$emit('input', suggestion.data.fias_id);
      }
    },
  }
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

.dadata-input {
  &__wrapper {
    position: relative;
  }

  &__input {
    position: relative;
    width: 100%;
    height: 54px;
    padding: 0 14px;
    border: 1px solid $input-border;
    box-sizing: border-box;
    border-radius: 5px;
    color: $brand-color;
    outline: none;

    ._input-error & {
      border-color: $error-border;
    }

    &::placeholder {
      color: $text-light;
    }
  }

  &__suggestions {
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 1px solid $input-border;
    border-top: 0;
    background: #fff;

    &-item {
      display: block;
      padding: 5px 14px;

      mark {
        background: none;
      }

      &_current {
        background: #eee;
      }
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    font-size: 11px;
    line-height: 1.4;
    color: #FF0000;
  }
}
</style>
