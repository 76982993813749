<template>
  <router-link :to="isLink ? url : ''" class="arrow-button" :class="{
    _empty: emptyLabel,
    _right: direction === 'right',
    _left: direction === 'left',
    _disabled: disabled,
  }">
    <span class="arrow-button__arrow">
      <template v-if="!emptyLabel">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 1H12V13" stroke="#3876FF" stroke-width="2"/>
          <line y1="-1" x2="14.2719" y2="-1" transform="matrix(-0.719412 0.694583 -0.719412 -0.694583 10.5908 1.3999)"
                stroke="#3876FF" stroke-width="2"/>
        </svg>
      </template>
      <template v-else>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.349609 1.3501H6.64961V7.6501" stroke="#3876FF" stroke-width="2"/>
          <line y1="-1" x2="7.42462" y2="-1" transform="matrix(-0.707106 0.707108 -0.707106 -0.707108 5.52441 1)"
                stroke="#3876FF" stroke-width="2"/>
        </svg>
      </template>
    </span>
    {{ !emptyLabel ? label : ''}}
  </router-link>
</template>

<script>
  export default {
    name: "ArrowButton",
    props: {
      url: {
        type: [String, Object],
        default: "#",
      },
      label: {
        type: String,
        default: "Читать",
      },
      emptyLabel: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      direction: {
        type: String,
        default: '',
      },
      isLink: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>

<style lang="scss">
  @import "../../scss/base/_settings.scss";

  $b: ".arrow-button";

  #{$b} {
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: $blue;
    padding-left: 64px;
    padding-right: 24px;
    text-decoration: none;
    transition: .3s;
    font-weight: bold;
    &._disabled {
      opacity: .4;
      pointer-events: none;
    }

    &._empty {
      line-height: unset;
      height: 22px;
      padding: 0 11px;
      &:hover {
        .arrow-button__arrow {
          width: 22px;
        }
      }
    }

    &._white {
      color: $white-true;
      &:hover {
        color: $blue;
        .arrow-button__arrow {
          background-color: $white-true;

          path,
          line {
            stroke: $blue;
          }
        }
      }
    }

    &._right {
      padding-right: 64px;
      padding-left: 24px;
    }

    &._center {
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      color: $white-true;

      .arrow-button__arrow {
        width: 100%;
        background-color: $blue;

        path,
        line {
          stroke: $white-true;
        }
      }
    }

    &__arrow {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 46px;
      height: 46px;
      margin-right: 16px;
      border: 1px solid $blue;
      border-radius: 24px;
      transition: .3s;

      svg {
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
      }

      ._empty & {
        width: 22px;
        height: 22px;
        margin-right: 0;

        svg {
          left: 6px;
        }
      }

      ._white & {
        border-color: $white-true;
        path,
        line {
          stroke: $white-true;
        }
      }

      ._right & {
        left: auto;
        right: 0;
        margin-right: 0;
        svg {
          transform: translateY(-50%) rotate(45deg);
          left: auto;
          right: 15px;
        }
      }

      ._left & {
        svg {
          transform: translateY(-50%) rotate(-135deg);
          left: 15px;
        }
      }
    }
  }
</style>
