<template>
  <div class="main-survey__list">
    <vue-masonry-wall :items="mainList" :options="optionMasonry">
      <template v-slot:default="{item}">
        <div class="main-survey__item" v-if="item">
          <img :src="item.imgUrl" class="main-survey__img">
          <div class="main-survey__category" v-html="item.category" v-if="item.category"></div>
          <h3 class="main-survey__title"><a :href="item.link"  v-html="item.title"></a></h3>
          <arrow-button :url="item.link" empty-label></arrow-button>
        </div>
      </template>
    </vue-masonry-wall>
  </div>
</template>

<script>
  import ArrowButton from "../ui/ArrowButton";
  import VueMasonryWall from "vue-masonry-wall";

  export default {
    name: "MainSurvey",
    components: {ArrowButton, VueMasonryWall},
    props: {
      mainList: {
        type: Array,
        default: () => [
        ],
      }
    },
    data() {
      return {
        optionMasonry: {
          width: 280,
          padding: {
            default: 16
          },
        },
        options: [
          {value: 2 , text: '2'},
          {value: 5 , text: '5'},
          {value: 10 , text: '10'},
          {value: 50 , text: '50'}
        ],
      }
    },
  }
</script>

<style lang="scss">
  @import "../../scss/base/include";
  .main-survey {
    &__list {
      margin-bottom: -64px;
      padding-top: 16px;
      padding-bottom: 16px;

      @include mobile {
        margin-bottom: 24px;
      }

      .masonry-item {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .masonry-item:not(:first-child) {
        @include mobile-min {
          display: none;
        }
      }
    }
    &__item {
      padding-bottom: 64px;
      @include mobile {
        padding-bottom: 32px;
      }
    }

    &__img {
      width: 100%;
      margin-bottom: 28px;
      @include mobile {
        margin-bottom: 24px;
      }
    }

    &__category {
      font-size: 12px;
      line-height: 140%;
      letter-spacing: 0.5em;
      text-transform: uppercase;
      color: $text-light;
      margin-bottom: 10px;
    }

    &__title {
      font-weight: 500;
      font-size: 26px;
      line-height: 120%;
      color: $brand-color;
      margin: 0 0 28px;
      @include tablet {
        font-size: 24px;
      }
      @include mobile {
        margin-bottom: 24px;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
</style>
