<template>
  <form @submit.prevent="submit" v-if="!error">
    <h2 class="form__title">Регистрация</h2>
    <h3 class="form__subtitle" v-if="!error || error !=2">2 шаг. Документы</h3>
    <h3 class="form__subtitle form__subtitle-bold" v-else>требуется Повторная загрузка документов</h3>
    <div class="form__description">
      <p>
        Для продолжения регистрации на&nbsp;сайте просим Вас предоставить фото или сканированную копию паспорта.
        Обращаем Ваше внимание, что указанные данные необходимы для целей подтверждения указанных Вами в&nbsp;анкете
        данных и&nbsp;их&nbsp;принадлежности Вам. Фотографическое изображение, содержащееся в&nbsp;паспорте, не&nbsp;используется. При
        сканировании страниц паспорта закройте, пожалуйста, <br> Вашу фотографию в&nbsp;документе.
      </p>
      <p>Загрузите скан/фото 2 и 3 страниц паспорта*</p>
    </div>
    <div class="form-row form-row_no-flex">
      <file-input input-name="file1" @change="onChangeFile1">
        <template #title>
          Разворот с фотографией (2 и 3 страницы паспорта)
        </template>
        <template #errors>
          <div class="fileinput__error" v-if="$v.form.file1.$error">
            <div v-if="!$v.form.file1.required">Выберите файл</div>
          </div>
        </template>
      </file-input>
      <p class="form__footnote">*Размер загружаемых файлов не должен превышать 10 Мб</p>
    </div>

    <div class="form-row form-row_mobile-wrap form-row_align-center">
      <button class="form__submit button button__lg button__blue" type="submit">Далее</button>
    </div>
    <div class="form-row form-row_mobile-wrap form-row_align-center">
      <delete-questionnaire-btn />
    </div>
  </form>
  <div class="sign-up-error" v-else>
    <template v-if="error === 1">
      <h2 class="form__title">Не удалось пройти проверку</h2>
      <p class="form__description form__description_align-center">
        К&nbsp;сожалению, Ваши анкетные данные не&nbsp;прошли проверку. Анкета будет автоматически удалена в&nbsp;течение 24&nbsp;часов.
      </p>
      <div class="form-row form-row_mobile-wrap form-row_align-center">
        <delete-questionnaire-btn />
      </div>
    </template>
    <template v-else-if="error === 3">
      <h2 class="form__title">Спасибо за регистрацию!</h2>
      <p class="form__description form__description_align-center">
        Ваши паспортные данные обрабатываются. О&nbsp;результатах обработки&nbsp;Вы будете проинформированы сообщением, которое будет отправлено на&nbsp;указанный Вами email в&nbsp;течение 3-х рабочих дней.
      </p>
       <div class="form__nav form-row form-row_align-center">
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        <delete-questionnaire-btn />
      </div>
    </template>
    <template v-else-if="error === 10">
      <h2 class="form__title">Системная ошибка</h2>
      <p class="form__description form__description_align-center">
        Пожалуйста, повторите попытку позже, или обратитесь <br/>в&nbsp;службу поддержки.
      </p>
    </template>
    <template v-else>
      <p class="form__description form__description_align-center" v-html="errorMsg" />
    </template>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from "../services/api";
import FileInput from './FileInput';
import DeleteQuestionnaireBtn from "./DeleteQuestionnaireBtn";

export default {
  name: "SignUpStep2",
  components: {
    DeleteQuestionnaireBtn,
    FileInput,
  },
  data() {
    return {
      error: null,
      iteration: 1,
      form: {
        file1: null,
      },
      errorMsg: '',
    };
  },
  validations: {
    form: {
      file1: {required},
    }
  },
  methods: {
    onChangeFile1(file) {
      this.form.file1 = file;
    },
    async submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        await this.$store.dispatch('showLoader');
        try {
          this.error = null;
          const result = await api.registerStep2(this.iteration, this.form.file1);
          if (result) {
            this.$emit('complete', this.$attrs.redirect);
            await this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.error = e.errors[0].code;
          this.errorMsg = e.errors[0].message;
          if ([3,4,5].indexOf(this.error) < 0 ) {
            await this.$store.dispatch('setErrors', e);
          }
          switch (this.error) {
            case 1: // Сервис IDX недоступен, блокировка
              // TODO UI - сообщить юзеру о неудачной проверке и предложить заново заполнить анкету
              await this.$store.dispatch('goblocked');
              break;
            case 2: // IDX ошибка "-200", некорректное изображение, грузим заново, проверим снова через parsePassportAuto
              this.form.file1 = null;
              break;
            case 3: // Просим пользователя повторно загрузить документы для проверки на бэке другим методом
              this.iteration++;
              break;
            case 4: // Паспорт не проверили, но пока что можно идти дальше
              this.$emit('complete');
              break;
            case 5:
              await this.$store.dispatch('goToInfoAboutPassportCheck');
              break;
          }
        } finally {
          await this.$store.dispatch('hideLoader');
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../scss/base/_settings.scss";
.sign-up-error {
  margin-top: -10px;
  margin-bottom: -15px;
  @include mobile {
    margin: 0;
  }
  .form__title {
    margin-bottom: 34px;
  }
  .form__description {
    font-size: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form__nav {
    margin-top: 58px;
  }
}
</style>
