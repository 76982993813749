<template>
  <div class="product-page__item">
    <div class="product-page__item-title">
      <div
        v-if="item.icon"
        class="product-page__item-img"
        :class="{click: item.modalText}" @click="openModal"
        data-tippy-content="Узнать больше"
      >
        <img :src="item.icon" alt=""/>
      </div>
      <div>
        <span v-html="item.title"></span>
        <!--<div v-if="item.disclaimer" class="product-page__disclaimer" v-html="item.disclaimer"></div>-->
      </div>
    </div>
    <div v-if="item.text" class="product-page__item-text" v-html="item.text"></div>
    <div v-if="item.disclaimer" class="product-page__disclaimer" v-html="item.disclaimer"></div>
  </div>
</template>

<script>
import tippy, {followCursor} from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  name: "ProductItem",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    openModal() {
      if (this.item.modalText) {
        this.$emit('modal:open', {top});
      }
    }
  },
  mounted() {
    tippy('[data-tippy-content].click', {
      followCursor: true,
      plugins: [followCursor],
    });
  }
}
</script>
