<template>
  <div class="my-survey">
    <div class="my-survey__header" :style="{ backgroundImage: `url(${header.background})` }">
      <div class="my-survey__header-wrap">
        <ul class="my-survey__breadcrumbs">
          <li v-for="(item, i) in breadcrumbs" :key="i">
            <a v-if="item.href" :href="item.href" v-html="item.text" />
            <span v-else v-html="item.text" />
          </li>
        </ul>
        <div class="my-survey__title" v-html="header.title" />
        <div class="my-survey__buttons start"  v-if="step === 'initial'">
            <arrow-button label="Пройти опрос" @click.native.prevent="startSurvey" />
          </div>
        <div v-else class="my-survey__text" v-html="header.text" />
      </div>
    </div>
    <div class="my-survey__body-wrap">
      <div
          :class="[
              'my-survey__body',
               `_${step}`,
               {'my-survey__body_contest' : mechanic.id}
               ]"
          class="_double">
        <div class="my-survey__top">
          <test-contest v-if="mechanic.id" :mechanic="mechanic" />
        </div>
        <div class="my-survey__bottom">
          <template v-if="step === 'initial'">
            <div class="my-survey__title" v-html="intro.title" />
            <div class="my-survey__text" v-html="intro.text" />
            <div class="my-survey__buttons start">
              <arrow-button label="Пройти опрос" @click.native.prevent="startSurvey" />
            </div>
          </template>
          <template v-if="step === 'survey'">
            <div class="my-survey__progress">
              <div class="my-survey__progress-count">
                <span>{{ leadingZero(stepsCurrent + 1) }}</span> / {{ leadingZero(stepsTotal) }}
              </div>
              <div class="my-survey__progress-bar">
                <div class="my-survey__progress-fill" :style="{ transform: `scaleX(${(stepsCurrent + 1) / stepsTotal})` }"></div>
              </div>
            </div>
            <div class="my-survey__step-image" v-if="stepData.picture"><img :src="stepData.picture"></div>
            <div class="my-survey__title" v-html="stepData.title" />
            <div class="my-survey__text">
              <ul class="my-survey__options">
                <li v-for="(item, i) in stepData.options" :key="`${stepsCurrent}-${i}`">
                  <label>
                    <radio-input
                      :value="item.value"
                      :label="item.text"
                      :name="`options-${stepsCurrent}`"
                      v-model="stepData.value"
                      @change="setOption"
                    />
                  </label>
                </li>
              </ul>
            </div>
            <div class="my-survey__buttons">
              <arrow-button label="Назад" direction="left" @click.native.prevent="goBack" />
              <arrow-button v-if="!isLastStep" label="Далее" :disabled="!stepData.value" direction="right" @click.native.prevent="goForth" />
              <arrow-button v-else label="Результат" direction="right" @click.native.prevent="finishSurvey" />
            </div>
          </template>
          <template v-if="step === 'result'">
            <img class="my-survey__image" :src="resultData.image" alt="">
            <div class="my-survey__title" v-html="resultData.title" />
            <div class="my-survey__text" v-html="resultData.text" />
            <div class="my-survey__buttons">
              <arrow-button label="Начать заново" direction="right" @click.native.prevent="resetSurvey" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <modal-finish-material ref="modalFinishMaterial"/>
  </div>
</template>

<script>
import ArrowButton from '../ui/ArrowButton';
import RadioInput from '../RadioInput';
import TestContest from "../block/TestContest";
import api from '../../services/api';

export default {
  name: 'MySurvey',
  components: {
    TestContest,
    ArrowButton,
    RadioInput,
    ModalFinishMaterial: () => import('../ui/modals/ModalFinishMaterial'),
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
    intro: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    mechanic: {
      type: Object,
      default() {return null},
    },
  },
  data() {
    return {
      step: 'initial',
      stepsLocal: this.setDefaultValue(this.steps),
      stepsCurrent: 0,
      resultData: {},
    };
  },
  computed: {
    stepsTotal() {
      return this.stepsLocal.length;
    },
    isLastStep() {
      return this.stepsCurrent === this.stepsTotal - 1;
    },
    stepData() {
      return this.stepsLocal[this.stepsCurrent];
    },
  },
  methods: {
    setDefaultValue(steps) {
      return steps.map((step) => ({
        ...step,
        value: null,
      }));
    },
    startSurvey() {
      this.step = 'survey';
    },
    async finishSurvey() {
      this.step = 'result';
      this.setResultData();

      const res = await api.sendFinishMaterial(this.header.id);

      if (res) {
        this.$refs.modalFinishMaterial.toggleShow();
      }
    },
    resetSurvey() {
      this.step = 'initial';
      this.stepsCurrent = 0;
      this.stepsLocal = this.setDefaultValue(this.steps);
    },
    goBack() {
      const current = this.stepsCurrent - 1;
      if (current >= 0) {
        this.stepsCurrent = current;
      } else {
        this.resetSurvey();
      }
    },
    goForth() {
      this.stepsCurrent = this.stepsCurrent + 1;
    },
    setOption(value) {
      this.stepsLocal[this.stepsCurrent].value = value;
    },
    setResultData() {
      const result = this.stepsLocal.reduce((acc, { value }) => {
        if (value.includes(',')) {
          value.split(',').forEach((v) => this.setResultValue(v.trim(), acc));
        } else {
          this.setResultValue(value, acc);
        }
        return acc;
      }, {});
      // TODO: remove
      console.log(result);
      const winner = Object.entries(result).reduce((acc, [key, value]) => {
        const { value: v = 0 } = acc;
        return value > v ? { key, value } : acc;
      }, {});
      this.resultData = this.results.find(({ value }) => value === winner.key);
    },
    setResultValue(value, acc) {
      acc[value] = (acc[value] || 0) + 1;
    },
    leadingZero(value) {
      return value < 10 ? `0${value}` : value;
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/_settings.scss';

$b: '.my-survey';
#{$b} {
  margin: 0 auto;
  overflow: hidden;

  &__header {
    margin-bottom: -50px;

    color: $white-true;
    min-height: 701px;
    background: no-repeat center center / cover;


    @include mobile {
      margin-bottom: 0;
      min-height: 502px;
    }
    &-wrap {
      max-width: 1440px;
      padding: 203px 112px 50px;
      margin-left: auto;
      margin-right: auto;
      @include low-desktop {
        padding-left: 60px;
        padding-right: 100px;
      }
      @include tablet {
        padding-top: 191px;

      }
      @include mobile {
        min-height: 436px;
        padding: 116px 15px 24px;
      }
    }
    .my-survey__buttons {
      margin-top: 35px;
      .arrow-button {
        color: $white-true;
        @include mobile {
          border-color: $white-true;
        }
        &__arrow {
          border-color: $white-true;
          background-color: transparent !important;
          path,
          line {
            stroke: $white-true;
          }
        }
      }
    }

  }
  &__breadcrumbs {
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #CAD1E0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 24px;
    padding: 0;
    li {
      flex-shrink: 0;
      position: relative;
      &:not(:first-child) {
        padding-left: 30px;
        &::before {
          content: '—';
          display: block;
          position: absolute;
          top: 0;
          left: 10px;
        }
      }
    }
    a {
      color: inherit;
      text-decoration: none;
      &:visited {
        color: inherit;
      }
      &:hover {
        color: $white-true;
      }
    }
  }
  &__title {
    font-weight: 500;
    #{$b}__header & {
      max-width: 675px;
      font-size: 56px;
      line-height: 1.1;
      margin-bottom: 32px;
      @include low-desktop {
        max-width: unset;
      }
      @include mobile {
        margin-bottom: 16px;
        font-size: 30px;
      }
    }
    #{$b}__body & {
      font-size: 32px;
      line-height: 1.2;
      color: $brand-color;
      margin-bottom: 40px;
      @include mobile {
        font-size: 30px;
        margin-bottom: 24px;
      }
    }
  }
  &__text {
    #{$b}__header & {
      max-width: 640px;
      font-size: 18px;
      line-height: 1.4;
      @include low-desktop {
        max-width: unset;
      }
      @include mobile {
        font-size: 16px;
      }
    }
    #{$b}__body & {
      max-width: 900px;
      font-weight: 500;
      font-size: 26px;
      line-height: 1.2;
      color: $text-light;
      @include low-desktop {
        max-width: unset;
      }
      @include mobile {
        font-size: 20px;
      }
    }
  }
  &__body {
    margin-left: 112px;
    padding: 0 212px 75px 0;
    background: $white-true;
    position: relative;
    &:not(.my-survey__body_contest) {
      padding-top: 50px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $white-true;
      top: 0;
      left: 100%;
    }
    @include low-desktop {
      margin-left: 30px;
      padding: 0 60px 75px 0;
    }
    @include mobile {
      margin-left: 0;
      padding: 30px 15px;
    }
    &-wrap {
      max-width: 1440px;
      margin: 0 auto;
    }
    &._result {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    br + br {
      content: '';
      &::before {
        content: '';
        padding-top: 14px;
      }
    }
  }
  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include mobile {
      margin-top: 24px;
      flex-direction: column-reverse;
    }
    .arrow-button {
      @include mobile {
        width: 100%;
        height: 48px;
        padding: 0 32px;
        border: 1px solid $blue;
        border-radius: 48px;
        text-align: center;
      }
      &:not(:last-child) {
        @include mobile {
          margin-top: 15px;
        }
      }
      &__arrow {
        @include mobile {
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          border: 0;
          width: calc(100% + 2px);
          height: 48px;
        }
      }
    }
    &.start {
      .arrow-button {
        @include mobile {
          padding-left: 64px;
        }
      }
    }
  }
  &__progress {
    margin-bottom: 48px;
    @include mobile {
      margin-bottom: 32px;
    }
    &-count {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: $brand-color;
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      span {
        font-weight: 500;
        font-size: 36px;
        line-height: 46px;
        text-transform: uppercase;
        color: $blue;
        margin-right: 4px;
      }
    }
    &-bar {
      height: 2px;
      background: rgba($black-true, 0.2);
      overflow: hidden;
    }
    &-fill {
      height: 2px;
      background: $blue;
      transition: transform 300ms;
      transform-origin: left center;
    }
  }
  &__options {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:not(last-child) {
        margin-bottom: 32px;
        @include mobile {
          margin-bottom: 16px;
        }
      }
    }
    .radio-input {
      &__field {
        &:checked + label::before {
          background-color: $blue;
        }
      }
      &__label {
        align-items: flex-start;
        &::before {
          width: 27px;
          height: 27px;
          margin-top: 4px;
          padding: 5px;
          border-color: $blue;
          @include mobile {
            width: 20px;
            height: 20px;
            margin-top: 0;
            margin-right: 10px;
            padding: 4px;
          }
        }
      }
    }
  }
  &__image {
    margin-bottom: 48px;
    @include mobile {
      margin-bottom: 24px;
    }
  }
  &__step-image {
    max-width: 800px;
    margin: 0 auto 40px;
    img {
      vertical-align: top;
      width: 100%;
    }
  }

  ._double {
    display: flex;
    flex-direction: column;
  }

  &__bottom {
    padding-left: 100px;
  }

  &__left {
    width: 70%;

    @include tablet {
      width: 100%;
      order: 2;
    }
  }

  &__right {
    width: 30%;

    @include tablet {
      width: 100%;
      margin-bottom: 25px;
      order: 1;
    }
  }
}

</style>
