<template>
  <my-modal>
    <form v-if="!sendSuccess" class="form">
      <h2 class="form__title">Документы</h2>
      <div class="form__subtitle _align-left">Паспорт</div>
      <div class="form__description">Загрузите скан/фото 2, 3 и 5 страницы паспорта (актуальная прописка)*</div>
      <div class="form-row form-row_no-flex">
        <file-input input-name="file1" @change="onChangeFile1">
          <template #title>
            Разворот с фотографией (2 и 3 страницы паспорта)
          </template>
          <template #errors>
            <div class="fileinput__error" v-if="$v.form.file1.$error">
              <div v-if="!$v.form.file1.required">Выберите файл</div>
            </div>
          </template>
        </file-input>
        <file-input input-name="file2" @change="onChangeFile2">
          <template #title>
            Страница с действующей пропиской (5 страница паспорта)
          </template>
          <template #errors>
            <div class="fileinput__error" v-if="$v.form.file2.$error">
              <div v-if="!$v.form.file2.required">Выберите файл</div>
            </div>
          </template>
        </file-input>
        <p class="form__footnote">*Размер загружаемых файлов не должен превышать 10 Мб</p>
      </div>
      <div class="form-row form-row_align-center form-row_mobile-wrap">
        <button class="form__submit button button__lg button__blue" type="button" @click.prevent="submitPassport" :disabled="documentsModerationStatuses && documentsModerationStatuses !== 'DocumentsModerationResend'">
          Отправить
        </button>
      </div>
    </form>

    <form v-if="showDocumentsForm" class="form" @submit.prevent="submitINN">
      <!-- <div v-if="innSegmentMsg" class="form__description">{{ innSegmentMsg }}</div> -->
      <div class="form__description" v-if="descriptionText">{{ descriptionText }}</div>
      <div class="form-row form-row_no-flex">
        <text-input
          label="ИНН"
          name="inn"
          v-model="inn"
          :error="$v.inn.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.inn.$error">
              <div v-if="!$v.inn.required">Заполните поле</div>
              <div v-if="$v.inn.required && !$v.inn.innValid">{{ innError.message }}</div>
            </div>
          </template>
        </text-input>
      </div>
      <div class="form-row form-row_align-center form-row_mobile-wrap">
        <button class="form__submit button button__lg button__blue" type="submit" :disabled="btnDisabled">
          Отправить
        </button>
      </div>
      <div class="form-row form-row_align-center">
        <router-link :to="{ path: '/cabinet' }" class="form__link">Назад</router-link>
      </div>
    </form>

    <div v-if="sendSuccess">
      <div class="form-row form-row_align-center">
        <p>
          Обновлённая информация сохранена
        </p>
      </div>
      <div class="form-row form-row_align-center">
        <router-link :to="{ path: '/cabinet' }" class="button button__blue button__lg">Назад</router-link>
      </div>
    </div>
  </my-modal>
</template>

<script>
import FileInput from '../FileInput';
import {required} from "vuelidate/lib/validators";
import api from "../../services/api";

const validateInn = function(inn, error) {
  var result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'ИНН пуст';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 10 или 12 цифр';
  } else {
    var checkDigit = function (inn, coefficients) {
      var n = 0;
      for (var i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10);
    };
    switch (inn.length) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true;
        }
        break;
    }
    if (!result) {
      error.code = 4;
      error.message = 'Указанный Вами ИНН некорректен';
    }
  }
  return result;
};

export default {
  name: "ProfileDocuments",
  components: {
    FileInput
  },
  data() {
    return {
      sendSuccess: false,
      inn: this.$store.state.profileData.customFields.iNN ?? '',
      innSegmentMsg: '',
      innModerationStatus: null,
      documentsModerationStatuses: null,
      form: {
        file1: null,
        file2: null,
      },
      innError: {
        code: '',
        message: ''
      }
    }
  },
  computed: {
    showDocumentsForm() {
      return !this.sendSuccess;
    },
    showInnForm() {
      return !this.sendSuccess && this.$store.state.currentUser.segments?.InnModerationInProcess !== undefined;
    },
    btnDisabled() {
      return this.innModerationStatus && this.innModerationStatus !== 'InnModerationResend';
    },
    descriptionText() {
      switch(this.innModerationStatus) {
        case 'InnModerationFail':
          return 'Указанный Вами ИНН некорректен';
        case 'InnModerationInProcess':
          return 'Указанный Вами ИНН находится в обработке';
        case 'InnModerationResend':
          return 'ИНН требуется указать повторно';
        default:
          return '';
      }
    }
  },
  validations: {
    form: {
      file1: {required},
      file2: {required},
    },
    inn: {
      required,
      innValid: function () {
        return validateInn(this.inn, this.innError)
      }
    },
  },
  async mounted() {
    try {
      this.$store.dispatch("showLoader");
      const result = await api.simplePostRequest('/api/checkSegmentations');
      if (result) {
        this.innModerationStatus = result.innModerationStatuses;
        this.documentsModerationStatuses = result.DocumentsModerationStatuses;
        this.inn = (this.innModerationStatus !== 'InnModerationResend') ? this.inn : '';
      }
    } catch (e) {
      this.$store.dispatch('setErrors', e);
    } finally {
      this.$store.dispatch("hideLoader");
    }
  },
  // mounted() {
  //   if (this.$store.state.currentUser.segments) {
  //     const segments = this.$store.state.currentUser.segments;
  //     switch (true) {
  //       case (segments.DocumentsModerationSuccess !== undefined):
  //         break;
  //       case (segments.InnModerationInProcess !== undefined):
  //         this.innSegmentMsg = 'Загруженная Вами информация находится в обработке';
  //         break;
  //       case (segments.InnModerationFail !== undefined):
  //         this.innSegmentMsg = 'Ваш ИНН отклонён';
  //         break;
  //       case (segments.InnModerationResend !== undefined):
  //         this.innSegmentMsg = 'Вам необходимо загрузить ИНН';
  //         break;
  //       case (segments.InnModerationSuccess !== undefined):
  //       default:
  //         break;
  //     }
  //   }
  // },
  methods: {
    onChangeFile1(file) {
      this.form.file1 = file;
    },
    onChangeFile2(file) {
      this.form.file2 = file;
    },
    async submitPassport() {
      this.$v.form.$touch();

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          const result = await api.updateDocuments(this.form);

          if (result) {
            this.$v.$reset();
            this.sendSuccess = true;
            this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);

        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },

    async submitINN() {
      this.$v.inn.$touch();

      if (!this.$v.inn.$pending && !this.$v.inn.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          const result = await api.updateInn(this.inn);

          if (result) {
            this.$v.$reset();
            this.sendSuccess = true;
            this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);

        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form {
  & + & {
    margin-top: 30px;
  }
}
</style>
