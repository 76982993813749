<template>
  <div class="test-contest" :class="position">
    <div class="test-contest__text">
      <div v-html="mechanic.shortDescription" />
      <div>
        Подробная информация о&nbsp;подарке и&nbsp;участниках доступна при нажатии на&nbsp;иконку.
      </div>
    </div>
    <div class="test-contest__prize">
      <div @click="$refs.promotion.toggleShow()">
        <i>
          <component :is="'Gift'" />
        </i>
      </div>
    </div>
    <modal-gift ref="gift" :mechanic="mechanic" />
    <modal-participation-promotion ref="promotion" @gift="showGift" :mechanic="mechanic" />
  </div>
</template>

<script>
import ModalParticipationPromotion from "../ui/modals/ModalParticipationPromotion";
import ModalGift from "../ui/modals/ModalGift";

export default {
  name: 'test-contest',
  components: { ModalParticipationPromotion, ModalGift },
  props: {
    position: { type: String, default:'horizontal' },
    mechanic: {
      type: Object,
      default() {
        return {
          shortDescription: '',
        };
      },
    },
  },
  methods: {
    showGift () {
      this.$refs.gift.toggleShow();
      this.$refs.promotion.toggleShow();
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/base/include";

$b: '.test-contest';

#{$b} {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #EBF1FE;
  padding-left: 100px;
  margin-bottom: 50px;

  @include tablet {
    padding-left: 0;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__text {
    display: flex;
    flex-direction: row;
    color: #1E418E;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    max-width: 628px;

    @include tablet {
      padding: 0 20px;
      flex-direction: column;
      max-width: 100%;
    }

    @include mobile {
      padding: 0 10px;
    }

    & > div {
      flex: 1;
      margin: 55px 0;

      &:first-child {
        position: relative;
        margin-right: 46px;
        padding-right: 27px;

        @include tablet {
          max-width: 100%;
          padding-right: 0;
          padding-bottom: 25px;
          margin: 25px 0 !important;
        }

        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background: rgba(30, 65, 142, 0.25);
          top: 0;
          right: 0;

          @include tablet {
            height: 1px;
            width: 100%;
            top: auto;
            bottom: 0;
            left: 0;
          }
        }
      }

      &:last-child {
        @include tablet {
          margin: 0 0 25px !important;
        }
      }
    }
  }

  &__prize {
    background-color: rgba(56, 118, 255, 0.06);
    padding: 37px 55px 46px;
    margin-left: 27px;

    @include tablet {
      margin-left: 0;
    }

    div {
      position: relative;
      cursor: pointer;
      width: 160px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include tablet {
        width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        width: 160px;
        height: 160px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 1px solid #3876FF;
        filter: drop-shadow(0px 20px 20px rgba(0, 30, 79, 0.25));
        border-radius: 50%;
      }
    }
  }

  .my-scrollbar {
    max-height: 250px;
    margin: 0;

    @include tablet {
      height: inherit;
    }
  }

  &.vertical {
    margin-left: 25px;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;

    #{$b}__text {
      flex-direction: column;

      & > div {
        padding: 0 25px;

        &:first-child {
          position: relative;
          padding-bottom: 25px;
          margin: 50px 0 25px!important;

          &:before {
            content: '';
            position: absolute;
            background: rgba(30, 65, 142, 0.25);
            height: 1px;
            width: 100%;
            top: auto;
            bottom: 0;
            left: 0;
          }
        }

        &:last-child {
          margin: 0 0 25px !important
        }
      }
    }

    #{$b}__prize {
      margin-left: 0;

      div {
        width: 100%;
      }
    }
  }
}
</style>
