<template>
  <div class="root">
    <div class="left-text _root-pe">
      <div class="left-text__back"></div>
      <div class="left-text__text">
        <h1 class="left-text__title">По&nbsp;обе стороны Японского моря</h1>
        <div class="left-text__root">
          <p>Каждую неделю вдохновляемся и&nbsp;изучаем только самое интересное&nbsp;— образ жизни, кухню, изобретения, архитектуру&nbsp;и, конечно, людей.</p>
          <router-link :to="{ path: pathPromo }" class="button button__lg button__blue button__arrow">Узнать больше</router-link>
        </div>
      </div>
    </div>
    <div class="root-more">
      <div class="container">
        <div class="root-more__inner">
          <p>узнайте больше <br/>о продуктах Mevius</p>
          <router-link class="button button__lg button__blue button__nav" :to="{ path: '/products/lss-sky-blue' }">перейти</router-link>
        </div>
      </div>
    </div>
    <div class="root-nav">
      <div class="root-nav__item">
        <router-link :to="{ path: '/products/ssb-sky-blue' }" style="background-image: url(/frontend/assets/i/products/top-sky-blue-infarct.png)" />
      </div>
      <div class="root-nav__item">
        <router-link :to="{ path: '/products/lss-sky-blue' }" style="background-image: url(/frontend/assets/i/products/top-sbs-sky-infarct.png)" />
      </div>
      <div class="root-nav__item root-nav__item_disabled root-nav__item_hide">
        <router-link :to="{ path: '/products/compact-crush' }" style="background-image: url(/frontend/assets/i/products/top-crush-infarct.png)" />
      </div>
      <div class="root-nav__item">
        <router-link :to="{ path: '/products/lss-original' }" style="background-image: url(/frontend/assets/i/products/top-original-infarct.png)" />
      </div>
      <div class="root-nav__item">
        <router-link :to="{ path: '/products/ssb-wind-blue' }" style="background-image: url(/frontend/assets/i/products/top-sbs-wind-infarct.png)" />
      </div>
      <div class="root-nav__item root-nav__item_disabled root-nav__item_hide">
        <router-link :to="{ path: '/products/lss-sky-blue' }" style="background-image: url(/frontend/assets/i/products/top-sbs-sky-infarct-limited.png)" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pathPromo: '/portal'
    }
  },
  mounted() {
    window.localStorage.getItem('applyConfidential', true) ? this.pathPromo = '/portal' : this.pathPromo
  }
}
</script>
