<template>
  <nav class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li
        v-for="(item, idx) in navList"
        :key="`breadcrumb-${idx}`"
        class="breadcrumbs__item"
      >
        <a :href="item.pathLink">{{item.label}}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  /*props: {
    linksData: { type: Array, default: [] }
  },*/
  computed: {
    navList() {
      return this.$route.name.split('-');
    }
  }
}
</script>
