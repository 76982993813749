<template>
  <div class="rules-nonogram">
    <div class="rules-nonogram__section">
      <div class="rules-nonogram__container">
        <div class="main-nonogram__description">
          Нонограмма — это скрытый рисунок на игровом поле, и Ваша задача — разгадать его.
        </div>
      </div>
    </div>
    <div class="rules-nonogram__section">
      <div class="rules-nonogram__container">
        <div class="main-nonogram__title">
          Как играть и как решать?
        </div>
        <div class="main-nonogram__description">
          Перед Вами клетчатое поле. Оно скрывает рисунок, который Вам предстоит разгадать. На краях поля расположены
          цифры, которые показывают количество групп цифр и количество цифр в каждой группе. Например, если вы видите
          запись «1 3 2», это означает, что нужно сначала закрасить одну клетку, затем три клетки подряд и, наконец, две
          клетки подряд. Между группами цифр обязательно должно быть хотя бы одно пустое незакрашенное поле.
        </div>
        <div class="rules-nonogram__steps">
          <div class="main-nonogram__description">
            Давайте рассмотрим правила игры и алгоритм
            <br> решения на простом примере.
          </div>
          <div class="rules-nonogram__step rules-nonogram-step">
            <div class="main-nonogram main-nonogram_scheme">
              <div class="main-nonogram__field">
                <table>
                  <tr>
                    <th>1</th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">2</span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">4</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span></div>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">1</span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="rules-nonogram-step__description">
              <p>
                Представим поле размером 5 на 5, и мы сразу видим столбец и строку с цифрами 5. Это означает, что нужно
                закрасить по 5 клеток в этих столбце и строке, то есть мы можем полностью заполнить их.
              </p>
            </div>
          </div>
          <div class="rules-nonogram__step rules-nonogram-step">
            <div class="main-nonogram main-nonogram_scheme">
              <div class="main-nonogram__field">
                <table>
                  <tr>
                    <th>2</th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">2</span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">4</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span></div>
                    </th>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">1</span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="rules-nonogram-step__description">
              <p>
                В первой строке стоит цифра 1, поэтому все клетки, кроме третьей, которая уже закрашена, не будут
                использованы. Поставим крестики в остальные клетки, чтобы указать, что их нельзя будет закрасить в будущем.
              </p>
            </div>
          </div>
          <div class="rules-nonogram__step rules-nonogram-step">
            <div class="main-nonogram main-nonogram_scheme">
              <div class="main-nonogram__field">
                <table>
                  <tr>
                    <th>3</th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">2</span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">4</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                    <td class="off"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span></div>
                    </th>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">1</span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td></td>
                    <td></td>
                    <td class="on"></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="rules-nonogram-step__description">
              <p>
                В четвертом столбце стоит цифра 4, и в нем осталось закрасить только 4 клетки. Закрасим их.
              </p>
            </div>
          </div>
          <div class="rules-nonogram__step rules-nonogram-step">
            <div class="main-nonogram main-nonogram_scheme">
              <div class="main-nonogram__field">
                <table>
                  <tr>
                    <th>4</th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">2</span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">4</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                    <td class="off"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span></div>
                    </th>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">1</span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="on"></td>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="rules-nonogram-step__description">
              <p>
                Правило, требующее наличия хотя бы одного пустого поля между группами, подсказывает, что в четвертой строке
                можно закрасить клетки только одним способом. Последовательно закрасим одну клетку, а затем еще две,
                получив две группы из 1 и 3 клеток, как указано слева от четвертой строки.
              </p>
            </div>
          </div>
          <div class="rules-nonogram__step rules-nonogram-step">
            <div class="main-nonogram main-nonogram_scheme">
              <div class="main-nonogram__field">
                <table>
                  <tr>
                    <th>5</th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">2</span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">4</span>
                      </div>
                    </th>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">2</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">1</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                    <td class="off"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">5</span></div>
                    </th>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item">1</span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="on"></td>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                  </tr>
                  <tr>
                    <th>
                      <div class="numbers">
                        <span class="numbers__item"></span>
                        <span class="numbers__item">3</span>
                      </div>
                    </th>
                    <td class="off"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="on"></td>
                    <td class="off"></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="rules-nonogram-step__description">
              <p>
                И окончательная закраска оставшихся клеток представлена в последней иллюстрации.
              </p>
              <div class="main-nonogram__title">
                Рисунок готов!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesNonogram"
}
</script>

<style lang="scss">
@import "../../scss/base/include";

.rules-nonogram {
  &__section {
    padding-top: 51px;

    @include mobile {
      padding-top: 30px;
    }

    &:not(:last-child) {
      padding-bottom: 59px;
      border-bottom: 1px solid $nonogram-cell-border;

      @include mobile {
        padding-bottom: 40px;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
  }

  &-step {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include mobile {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &:first-child {
      margin-top: 124px;

      @include mobile {
        margin-top: 40px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 72px;

      @include mobile {
        margin-bottom: 53px;
      }
    }

    &__description {
      flex: 1;
      max-width: 285px;
      margin-left: 38px;

      @include mobile {
        flex: none;
        margin-left: 0;
        margin-top: 16px;
        max-width: 257px;
      }

      p {
        color: $brand-color;
        font-size: 18px;
        line-height: 140%;
        margin: 0;

        @include mobile {
          font-size: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 19px;

          @include mobile {
            margin-bottom: 53px;
          }
        }
      }
    }
  }
}
</style>
