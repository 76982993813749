<template>
  <modal :show.sync="show" class="_center _pr0">
    <div class="participation-promotion__wrapper">
      <div class="participation-promotion__title">Поздравляем!<br>Вам доступен подарок!</div>
      <div class="participation-promotion__description">
        Подробная информация о подарке, условиях и порядке вручения будет отправлена на адрес
        Вашей электронной почты в течение 7 рабочих дней.
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'ModalFinishMaterial',
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
}
</script>

<style lang="scss">
@import "../../../scss/base/include";

$b: '.participation-promotion';

#{$b} {
  max-width: 657px;
  right: 0;
  position: relative;
  top: auto!important;

  &__title {
    position: relative;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #1E418E;
    padding-bottom: 50px;
    padding-right: 32px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #E8EBF1;
    }
  }

  &__description {
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #7A8BB2;
    padding-right: 32px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #E8EBF1;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 0;
    padding-bottom: 40px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #EBF1FE;
    border-radius: 15px;
    padding: 12px 19px 14px 18px;
    margin-right: 22px;

    @include tablet {
      margin-right: 34px;
      flex-direction: column;
    }

    &:first-child {
      background: $brand-color;

      #{$b}__item-key,
      #{$b}__item-value {
        color: #ffffff !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-type {
      display: flex;
      flex-direction: column;
      color: #7A8BB2;
      width: calc(100% / 6);

      @include tablet {
        width: 50% !important;
      }

      &:nth-child(1) {
        width: calc(100% / 6 * 1.3);
      }

      &:nth-child(3) {
        width: calc(100% / 6 * 2.7);

        @include tablet {
          width: 100% !important;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        #{$b}__item-value {
          color: #3876FF;
        }
      }

      &:nth-child(4) {
        #{$b}__item-value {
          cursor: pointer;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &-key {
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #7A8BB2;

      @include tablet {
        margin-bottom: 5px;
      }
    }

    &-value {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: $brand-color;

      @include tablet {
        margin-bottom: 15px;
      }
    }
  }

  &__scrollbar {
    .profile-activity__event-name {
      color: #1e418e;
      font-size: 16px;
    }

    .profile-activity__event-status.possible {
      color: $blue;
      font-size: 16px;
    }
  }

  .vue-scrollbar__area {
    overflow: auto;
  }
}

.modal__wrapper._center .modal {
  @media screen and (min-width: 1024px) {
    left: 50% !important;
    top: 50% !important;
    transform: translateY(-50%) translateX(-50%);
    max-width: 660px;
  }
}

.modal__wrapper._pr0 .modal {
  padding-right: 0;
}
</style>
