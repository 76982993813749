<template>
  <div class="fileinput" ref="fileDrop"
      :class="{empty: !value}"
       @dragenter.prevent="highlight"
       @dragover.prevent="highlight"
       @dragleave.prevent="unhighlight"
       @drop.prevent="handleDrop($event)"
  >
    <h3 class="fileinput__title">
      <slot name="title"/>
    </h3>
    <!-- a href="" v-if="value != ''" class="fileinput__delete" @click.prevent="clearInput" title="Удалить"></a-->
    <label>
      <input type="file" :name="inputName" @change.prevent="getInputValue" ref="fileInput">
      <span class="fileinput__button">Выбрать файл</span>
      <span class="fileinput__label">
        <template v-if="value === ''">Файл не выбран</template>
        <template v-else>{{ value }}</template>
      </span>
    </label>
    <slot name="errors"/>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    inputTitle: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      maxSize: 10 * 1024 * 1024,
    }
  },
  methods: {
    getInputValue() {
      if (this.$refs.fileInput.files[0].size > this.maxSize) {
        this.$store.dispatch('setErrors', {errors: [{customData: ['Превышен размер файла']}]});
        setTimeout(() => {
          this.$store.dispatch('hideErrorsBag');
          this.$store.dispatch('clearErrors');
        }, 2000);
        return false;
      } else {
        this.value = this.$refs.fileInput.value.replace(/C:\\fakepath\\/i, '');
        this.$emit('change', this.$refs.fileInput.files[0]);
      }
    },
    clearInput() {
      this.value = '';
      this.$refs.fileInput.value = '';
    },
    handleDrop(e) {
      this.unhighlight();
      let dt = e.dataTransfer;
      let file = dt.files[0];
      if (file.size > this.maxSize) {
        this.$store.dispatch('setErrors', {errors: [{customData: ['Превышен размер файла']}]});
        setTimeout(() => {
          this.$store.dispatch('hideErrorsBag');
          this.$store.dispatch('clearErrors');
        }, 2000);
        return false;
      } else {
        this.value = file.name;
        this.$emit('change', file);
      }
    },
    highlight() {
      this.$refs.fileDrop.classList.add('_highlight');
    },
    unhighlight() {
      this.$refs.fileDrop.classList.remove('_highlight');
    }
  },
}
</script>
