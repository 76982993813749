<template>
  <div class="product-page">
    <div class="left-text">
      <div
        class="left-text__back"
        :style="{
          height: textHeight + 'px',
        }"
      >
        <div
          class="left-text__parallax"
          :style="{
            height: 100 * offsets.length + 'vh',
            transform: parallaxTransform2,
          }"
        ></div>
      </div>
      <div class="left-text__text"></div>
    </div>
    <div
      class="parallax-bg"
      :style="{
        height: 100 * offsets.length + '%',
        transform: parallaxTransform,
      }"
    ></div>
    <div class="sections-next" @click="scrollToSection(activeSection + 1)" v-if="activeSection != offsets.length - 1" :style="{
        marginBottom: textHeight + 'px',
      }">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M0.788574 0.884466L5.50011 5.86523L10.2117 0.884466" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div class="sections-prev" @click="scrollToSection(activeSection - 1)" v-if="activeSection != 0" >
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M10.2114 6.78839L5.49989 1.80762L0.788349 6.78839" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div
      class="sections-menu"
      :style="{
        marginTop: -(textHeight - headerHeight) / 2 + 'px',
      }"
    >
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      >
      </span>
    </div>
    <div ref="packOverlay" class="cigarettes-pack-page cigarettes-pack-page-2">
      <iframe id="cigarettes-pack-page__model"
              src="https://www.vectary.com/viewer/v1/?model=ef987679-4930-4a29-93cf-8995717166c9&arAllowScaling=0&lang=ru&showPreloader=1&showPlaceholder=0&showInteractionPrompt=0&zoom=0&pan=0&rotateY=0&doubleClickToFocus=0&env=kiara9dusk&exposure=10&gamma=1.5&loadGlb=1"
              allowtransparency="false"
              frameborder="0"
              width="100%"
              height="100%">
      </iframe>
      <transition name="fade">
        <div class="cigarettes-pack-page__360" v-if="show360"></div>
      </transition>
      <div id="cigarettes-pack-page-2__handle">
        <svg width="64" height="121" viewBox="0 0 64 121" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M48.2829 59.1517C47.8754 61.4609 48.4187 70.2899 50.592 70.6974L53.4444 51.5452C53.5803 50.866 54.1236 47.8778 52.9011 46.3836C52.0861 45.297 50.3203 43.9387 47.1962 44.7536L46.6529 48.2853C48.0112 47.6061 48.8262 48.2853 49.2337 48.6927C49.6412 49.2361 49.6412 50.1869 49.5053 50.5944L48.2829 59.1517ZM34.5639 38.2338C37.4164 37.5546 39.318 38.2338 40.133 39.3204C41.763 41.3579 41.0838 44.7536 40.948 45.4328L38.3672 63.9058C36.1939 63.4983 35.3789 56.0276 35.6506 53.8543L36.8731 44.6178C37.0089 44.2103 37.0089 43.1237 36.6014 42.5803C36.1939 42.037 35.3789 41.4937 34.0206 42.1729L34.5639 38.2338ZM24.1049 60.2384C21.9316 59.8309 21.1167 55.0768 22.3391 49.6436C24.9199 37.6904 27.5007 17.4516 28.3157 13.5125C29.674 6.17763 28.859 4.54765 24.5124 3.59684C21.5242 2.91768 18.5359 5.22681 17.7209 7.53594V6.72095C18.6717 1.15188 22.0675 0.608554 25.1916 0.744385C28.7232 0.880216 32.934 3.59684 32.5265 8.07926C31.8473 12.8333 27.3649 42.852 24.1049 60.2384ZM26.6857 120.547C26.6857 120.547 13.5101 120.412 5.08859 110.088C-2.38212 100.988 1.42115 82.1072 1.42115 82.1072L3.86612 69.0674C5.90359 69.4749 6.58274 74.229 5.90358 77.3531L5.08859 82.9222C5.08859 83.058 0.877826 100.173 8.48436 108.323C13.1026 113.348 19.3509 116.608 27.9082 117.016C36.4656 117.423 42.3063 115.522 47.0604 110.224C55.2103 101.259 56.9761 86.7254 57.6552 83.058L59.6927 67.8449C59.6927 67.1658 59.9643 66.215 59.5568 65.8075C59.1494 65.4 58.3344 64.7208 56.976 65.2641L57.3835 61.7325C59.8285 60.7817 61.4585 62.14 62.2735 63.0908C64.0393 65.1283 63.4959 67.8449 63.3601 68.5241L61.5943 82.1072C61.5943 82.1072 59.5568 102.482 50.592 112.398C50.7278 112.398 43.5288 121.906 26.6857 120.547Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div
      id="fp"
      :style="{
        height: containerHeight + 'px',
      }"
    >
      <section
        class="fullpage"
        :class="{active: animateIndex == index}"
        v-for="(item, key, index) in $slots"
        :key="index"
      >
        <div>
          <transition name="slide-left">
            <div
              v-show="animateIndex == index"
              class="animate-block"
              ref="anim"
            >
              <slot :name="key"></slot>
            </div>
          </transition>
          <div v-if="nextButton.url && offsets.length == index + 1" class="_g-hidden-tablet">
            <div class="root-more _product">
              <div class="container">
                <transition name="slide-right">
                  <div v-show="animateIndex == index">
                    <router-link :to="{ path: nextButton.url }" class="button button__lg button__blue button__arrow" v-html="nextButton.text"></router-link>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import { VctrApi } from "./../vectary";
import productMixin from "../mixins/productMixin";

export default {
  name: 'ProductPage',
  mixins: [productMixin],
  methods: {
    async animatePack() {
      let state = {};
      switch (this.activeSection) {
        case 0:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [-0.3583560116773057, 0.07698401066271571, 0.16176617559651857],
            "zoom": 1.15
          };
          break;
        case 1:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [0.0453044329937353, 0.0800153711829016, 0.3889072569769392],
            "zoom": 1.15
          };
          break;
        case 2:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [-0.3509688660158986, 0.08987639455515009, 0.1759811500267152],
            "zoom": 1.15
          };
          break;
        case 3:
          state ={
            "target": [-0.0004406095547030142, 0.08877903385319294, -0.0019180448538658468],
            "position": [-0.0168908811668965752, 0.29871730157081133, -0.05580004308324268],
            "zoom": 0.9
          };
          break;
        case 4:
          state = {
            "target": [-0.0004406095547023481, 0.05977903385319294, -0.001918044853867179],
            "position": [0, 0.0769840106627164, 0.33734226558641245],
            "zoom": 1
          };
          break;
        case 5:
          state = {
            "target": [-0.0004406095547023481, 0.08807903385319294, 0.002918044853867179],
            "position": [-0.18640581723661007, 0.19791726198649588, 0.07823845981005313],
            "zoom": .8
          };
          break;
        case 6:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [-0.1999359750673202, 0.0769840106627164, 0.33734226558641245],
            "zoom": 1.15
          };
          break;
      }

      //движение руки
      const handle = document.querySelector('#cigarettes-pack-page-2__handle');
      if (this.activeSection === 3) {
        handle.style.opacity = '1';
        handle.style.animation = '2s linear 2s infinite alternate go-left-right';
        handle.style.animationIterationCount = '6';
      } else {
        handle.style.opacity = '0';
        setTimeout(() => {
          handle.style.animation = 'none';
        }, 500);
      }

      //изменение высоты на мобилке
      (this.activeSection > 0 && this.activeSection < 6) ?
        this.$refs.packOverlay.classList.add('mobile-rotate'):
        this.$refs.packOverlay.classList.remove('mobile-rotate');

      // на 4 и 5 экранах открываем пачку, если она закрыта и вытаскиваем сигарету
      if (this.activeSection === 4 || this.activeSection === 5) {
        this.animatePackPosition(state, 2000);
        if (!this.isUp) {
          this.isClosed = false;
          this.isUp = true;
          await this.switchPack(true);
          setTimeout(() => { this.moveCigarette(true)}, 2000);
        }

        return;
      }

      // анимация пачки
      if (!this.isClosed) {
        this.isClosed = true;
        this.isUp = false;
        await this.moveCigarette(false);
        this.animatePackPosition(state);
        setTimeout(() => {
          this.switchPack(false)}, 700
        );
      } else {
        this.animatePackPosition(state, 1500);
      }
    },
    async moveCigarette(isOpen) {
      const cigaretteObjectName = 'сигарета';
      const currentPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
      const yPosition = isOpen ? 21.8756126785278298 : 11.78772156310984;

      VctrApi.Utils.animate(
        500,
        "easeInOutQuad",
        async (timeFraction) => {
          const position = VctrApi.Utils.lerp(currentPosition, [currentPosition[0], yPosition, currentPosition[2]], timeFraction);

          await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, position);
        });
    },
    async switchPack(isOpen) {
      const capObjectName = 'крышка';
      const currentRotation = await this.vectaryApi.getRotation(capObjectName);
      const zPosition = isOpen ? 5 : -8.825295124270959;

      if (isOpen) {
        setTimeout(async () => {
          const newPosition = await this.vectaryApi.getPosition(capObjectName);

          VctrApi.Utils.animate(
            300,
            "easeInOutQuad",
            async (timeFraction) => {
              const position = VctrApi.Utils.lerp(newPosition, [newPosition[0], newPosition[1], -5.825295124270959], timeFraction);

              await this.vectaryApi.setPositionAbsolute(capObjectName, position);
            });
        }, 800)
      } else {
        const newPosition = await this.vectaryApi.getPosition(capObjectName);

        VctrApi.Utils.animate(
          300,
          "easeInOutQuad",
          async (timeFraction) => {
            const position = VctrApi.Utils.lerp(newPosition, [newPosition[0], newPosition[1], 3.9], timeFraction);

            await this.vectaryApi.setPositionAbsolute(capObjectName, position);
          });
      }

      setTimeout(async () => {
        const currentPosition = await this.vectaryApi.getPosition(capObjectName);

        VctrApi.Utils.animate(
          700,
          "easeInOutQuad",
          async (timeFraction) => {
            const position = VctrApi.Utils.lerp(currentPosition, [currentPosition[0], currentPosition[1], zPosition], timeFraction);
            const rotation = VctrApi.Utils.lerp(currentRotation, [isOpen ? -100 : 0, 0, 0], timeFraction);

            await this.vectaryApi.setRotationAbsolute(capObjectName, rotation);
            await this.vectaryApi.setPositionAbsolute(capObjectName, position);
          });
      }, isOpen ? 0 : 400)
    },
  },
};
</script>
<style lang="scss">
@import "../scss/base/_settings.scss";
#fp {
  overflow: hidden;
  position: relative;
  z-index: 11;
  @include tablet-min {
    height: 100vh;
  }
}

.fullpage {
  min-height: 100vh;
  min-height: var(--app-height);
  width: 100%;
  overflow: auto;
  display: flex;
  @include tablet {
    min-height: 100vh;
  }
  @include mobile {
    padding-bottom: 0;
  }
  & > div {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    @include tablet {
      justify-content: flex-end;
    }
    @include mobile {
      min-height: calc(100vh - 60px);
      min-height: calc(var(--app-height) - 60px);
    }
  }
}

.parallax-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: 600ms;
  transition-timing-function: ease;
  background: center top url(/frontend/assets/i/bg-product.png)
    no-repeat;
  @include tablet {}
  @include mobile {
    background-size: 520px;
  }
}

.cigarettes-pack-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: calc(100vh - 134px);
  max-width: 760px;
  right: 170px;
  top: 70px;

  @include low-desktop {
    max-width: 570px;
    right: 60px;
  }

  @include tablet {
    max-height: 59%;
    max-width: 100%;
    transition: max-height 2s ease-in-out;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    overflow: visible;
  }

  @include mobile {
    top: 44px;
    max-height: 280px;
  }

  &__360 {
    position: absolute;
    width: 60px;
    height: 80px;
    background-color: coral;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: center top url(/frontend/assets/i/360.png) no-repeat;
    background-size: contain;
    @include tablet {
      bottom: auto;
      top: 10px;
      width: 50px;
      height: 60px;
    }
    @include mobile {
      top: 0;
      width: 40px;
      height: 45px;
    }
  }

  iframe {
    max-width: 100%;
  }
}

.mobile-rotate {

  @include tablet {
    max-height: 74%;
    transition: max-height 1s ease-in-out;
  }

  @include mobile {
    max-height: 70%;
  }
}

@keyframes go-left-right {
  from {
    left: 31%;
    top: 27%;
  }
  to {
    left: 61%;
    top: 57%;
  }
}


@include low-desktop {
  @keyframes go-left-right {
    from {
      left: 24%;
      top: 25%;
    }

    to {
      left: 67%;
      top: 56%;
    }
  }
}

@include tablet {
  @keyframes go-left-right {
    from {
      left: 34%;
      top: 26%;
    }

    to {
      left: 61%;
      top: 56%;
    }
  }
}

@include mobile {
  @keyframes go-left-right {
    from {
      left: 29%;
      top: 27%;
    }
    to {
      left: 63%;
      top: 57%;
    }
  }
}

#cigarettes-pack-page-2__handle {
  position: absolute;
  left: 31%;
  top: 27%;
  opacity: 0;
  transition: linear .4s;

  @include low-desktop {
    left: 24%;
    top: 25%;
  }

  @include tablet {
    left: 34%;
    top: 26%;
  }

  @include mobile {
    left: 29%;
    top: 27%;
  }

  svg {
    @include mobile {
      width: 32px;
      height: 60px;
    }
  }
}

.sections-prev,
.sections-next {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 15px rgba(7, 32, 101, 0.25);
  z-index: 1010;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background-color: $white-true;
  }
  @include tablet {
    transform: none;
    left: auto;
    right: 12.5px;
  }
  @include mobile {
    width: 24px;
    height: 24px;
    right: 7px;
  }
}

.sections-prev {
  top: 82px;
  @include tablet {
    top: 110px;
  }
  @include mobile {
    top: 64px;
  }
  svg {
    margin-top: -2px;
    @include mobile {
      width: 9px;
    }
  }
}

.sections-next {
  bottom: 46px;
  @include tablet-min {
    margin-bottom: 0 !important;
  }
  @include tablet {
    bottom: 40px;
  }
  @include mobile {
    bottom: 20px;
  }
  svg {
    margin-bottom: -2px;
    @include mobile {
      width: 9px;
    }
  }
}

.sections-menu {
  position: absolute;
  z-index: 1010;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white-true;
  padding: 15px 7px;
  box-shadow: 0 0 10px rgba(0, 30, 79, 0.25);
  border-radius: 20px;
  transition-duration: 600ms;
  @include tablet-min {
    margin-top: 0 !important;
  }
  @include mobile {
    right: 11px;
    padding: 11px 5px;
  }
}

.sections-menu .menu-point {
  width: 6px;
  height: 6px;
  background-color: $brand-color;
  display: block;
  opacity: 0.32;
  transition: 0.4s ease all;
  cursor: pointer;
  border-radius: 50%;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  @include mobile {
    width: 4.2px;
    height: 4.2px;
    &:not(:last-child) {
      margin-bottom: 9.8px;
    }
  }
}

.sections-menu .menu-point.active {
  opacity: 1;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s;
  opacity: 1;
  transform: translateX(0);
  @include tablet {
    transform: translateY(0);
  }
}
.slide-left-enter-active {
  transition-delay: 0.5s;
}

.slide-left-enter,
.slide-left-leave-active {
  opacity: 0 !important;
  transform: translateX(-50vw);
  @include tablet {
    transform: translateY(100%);
  }
}

.slide-right-enter-active {
  transition-delay: 0.5s;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all ease 0.5s;
  opacity: 1;
  transform: translateX(0);
}
.slide-right-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(50vw);
}
.animate-block {
  height: 100%;
  opacity: 0;
  display: block !important;
  transform: translateX(-50vw);
  .active & {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.5s;
    @include tablet {
      transform: translateY(0);
    }
  }
  @include tablet {
    margin-top: 514px;
    transform: translateY(100%);

    height: auto;
    justify-self: end;
    .left-text {
      position: relative;
      padding-bottom: 55px;
    }
  }
  @include mobile {
    margin-top: 290px;
    .left-text {
      padding-bottom: 16px;
    }
  }
  .footer {
    @include mobile {
      width: calc(100% + 32px);
      margin: 16px -16px -16px;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

