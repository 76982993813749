<template>
  <div class="my-modal" :class="{ 'my-modal_top-index': !$store.state.isAuthenticated }">
    <div class="my-modal__dialog">
      <div class="my-modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyModal",
  props: {
    closable: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/base/_settings.scss";
.my-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  outline: 0;
  overflow: auto;
  @include mobile {
    padding: 15px;
    bottom: 60px;
  }

  &_top-index {
    z-index: auto;
    .my-modal__content {
      position: relative;
      z-index: 9999;
    }
  }

  &__dialog {
    position: relative;
    width: auto;
    margin: auto;
    flex-grow: 1;
    max-width: 100%;
  }

  &__content {
    background: #FFFFFF;
    box-shadow: 0 0 50px rgba(0, 30, 79, 0.3);
    border-radius: 15px;
    padding: 40px 50px 50px;
    margin: 100px auto 50px;
    @include mobile {
      padding: 20px 15px;
      margin: 50px auto 0;
    }

    .profile & {
      max-width: 705px;
    }
  }
}
</style>
