<template>
  <div
      class="dropdown"
      :class="{ 'dropdown_open' : isOpen}"
      ref="dropdown"
  >
    <div class="dropdown__head">
      <div class="dropdown__title">{{ title }}</div>
      <button
          class="dropdown__btn"
          @click.prevent="isOpen = !isOpen"
      >
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 5H5V10" stroke="#3876FF" stroke-width="2"/>
          <path d="M10 5L5 5L5 -3.97233e-08" stroke="#3876FF" stroke-width="2"/>
        </svg>
      </button>
    </div>
    <transition name="slide-down">
      <div
          class="dropdown__body"
          v-show="isOpen"
      >
        <slot>
          Нет контента
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Заголовок отсутсвует'
    },
  },
  name: "MyDropdown",
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    isOpen () {
      if (this.isOpen) {
        this.$nextTick(() => {
          const heightHeader = document.querySelector('.header').offsetHeight;
          const marginTopDropdown =  window.getComputedStyle(this.$refs.dropdown).marginTop.replace('px', '');
          const position = this.$refs.dropdown.getBoundingClientRect().top - heightHeader - marginTopDropdown;
          window.scrollBy({
            top: position,
            behavior: 'smooth'
          })
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import "../scss/base/include";
.dropdown {
  background-color: $nonogram-header-bg;
  border-radius: 15px;
  padding: 16px 30px;
  margin-top: 17px;

  @include mobile {
    padding: 13px 16px;
  }

  &_open {
    padding-bottom: 191px;

    @include mobile {
      padding-bottom: 25px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-top: 39px;
    border-top: 1px solid $nonogram-cell-border;

    @include mobile {
      margin-top: 30px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    color: $text-light;

    @include mobile {
      font-size: 20px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid $blue;
    border-radius: 50%;
    cursor: pointer;
    transition: transform .15s linear;
    margin-right: -9px;

    @include mobile {
      margin-right: -5px;
    }

    .dropdown_open & {
      transform: rotate(-45deg);
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .slide-down-enter-active,
  .slide-down-leave-active {
    transform-origin: top;
  }

  .slide-down-enter-to,
  .slide-down-leave {
    transform: scaleY(1);
    transition: transform .25s linear;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    transform: scaleY(0);
  }
}
</style>
