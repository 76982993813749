<template>
  <a href="#" class="form__link" @click.prevent="sendRequest">{{deletionStatus ? 'Восстановить анкету' : 'Удалить анкету'}}</a>
</template>

<script>
  import api from "../services/api";

  export default {
    name: "DeleteQuestionnaireBtn",
    computed: {
      deletionStatus() {
        return this.$store.state.deletionStatus;
      }
    },
    methods: {
      async sendRequest() {
        if (this.deletionStatus) {
          await api.simplePostRequest('/api/deletionRecall');
          this.$store.commit('setDeletionStatus', null);
        } else {
          this.$root.$emit('signUpDeletionStep');
        }
      }
    }
  }
</script>
