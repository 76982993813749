<template>
  <div>
    <template v-if="changeType === 'email'">
      <div v-if="step === 1">
        <h2 class="form__title">Редактировать электронную почту</h2>
        <div v-if="profile.pendingEmail && profile.pendingEmail && profile.pendingEmail.length" class="form__description">
          Электронный адрес, ожидающий подтверждения: {{profile.pendingEmail}}
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Новая электронная почта*"
            v-model="$v.email.$model"
            @input="error = null"
            name="email"
            :error="$v.email.$error || error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.email.$error || error">
                <div v-if="!$v.email.required">
                  Обязательное поле
                </div>
                <div v-else-if="!$v.email.email">
                  Неверный формат электронной почты
                </div>
                <div v-else-if="error">
                  Эта электронная почта уже используется. Укажите другой адрес электронной почты.
                </div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form__footnote">*Обязательное поле</div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="sendEmail">Сохранить</button>
        </div>
        <div class="form__description_align-center">
          <a href="#" class="form__link" @click.prevent="returnToProfile">Назад</a>
        </div>
      </div>
      <div v-if="step === 2">
        <div class="form-row form-row_align-center">
          <p>
            Письмо отправлено на <span class="form__link">{{email}}</span> <br>
            Для подтверждения смены электронного адреса перейдите, пожалуйста, по ссылке из письма
          </p>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="returnToProfile">Вернуться</button>
        </div>
      </div>
    </template>
    <template v-if="changeType === 'phone'">
      <div v-if="step === 1">
        <h2 class="form__title">Редактировать номер телефона</h2>
        <div v-if="profile.pendingMobilePhone" class="form__description">
          Телефон, ожидающий подтверждения: +{{profile.pendingMobilePhone}}
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Номер телефона*"
            v-model="$v.phone.$model"
            @input="error = null"
            name="phone"
            type="tel"
            :error="$v.phone.$error || error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.phone.$error || error">
                <div v-if="!$v.phone.required">
                  Обязательное поле
                </div>
                <div v-else-if="!$v.phone.phoneRegex">
                  Неверный формат телефона
                </div>
                <div v-else-if="error">
                  Этот телефон уже используется. Укажите другой номер.
                </div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form__footnote">*Обязательное поле</div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="sendPhone">Сохранить</button>
        </div>
        <div class="form__description_align-center">
          <a href="#" class="form__link" @click.prevent="returnToProfile">Назад</a>
        </div>
      </div>
      <div v-if="step === 2">
        <h2 class="form__title">Подтверждение номера</h2>
        <div class="form__description">
          <p>
            На указанный номер поступит звонок.<br>
            Пожалуйста, введите код, который будет продиктован по телефону:
          </p>
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Введите код"
            name="phone-code"
            autocomplete="off"
            v-model="$v.phoneCode.$model"
            :error="$v.phoneCode.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.phoneCode.$error">
                <div v-if="!$v.phoneCode.required">Заполните поле</div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form-row form-row_mobile-wrap">
          <span class="form__send-again">
            <a
              class="form__link"
              href="#"
              @click.prevent="changePhone"
            >Отправить код повторно</a>
            &nbsp;
            <span v-show="!resendAvailable">(через {{ resendTimeLeft }} сек.)</span>
          </span>
          <button class="button button__lg button__blue" @click.prevent="confirmCode">Подтвердить</button>
        </div>
      </div>
      <div v-if="step === 3">
        <div class="form-row form-row_align-center">
          <p>
            Ваш телефон успешно обновлён
          </p>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="returnToProfile">Вернуться</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {required, email, helpers} from "vuelidate/lib/validators"
import api from "../../services/api"

const phoneRegex = helpers.regex('val1', /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ -]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/);

export default {
  name: "ProfileCredentialsChange",
  props: {
    changeType: {
      type: String,
      default: 'email'
    },
    profile: {
      type: Object,
    }
  },
  data() {
    return {
      email: '',
      phone: '',
      phoneCode: '',
      step: 1,
      resendAvailable: true,
      resendTimeLeft: 30,
      error: null
    }
  },
  validations: {
    email: {required, email},
    phone: {required, phoneRegex},
    phoneCode: {required},
  },
  mounted() {
    if (this.profile.pendingEmail && this.profile.pendingEmail.length) {
      this.email = this.profile.pendingEmail;
    }
    if (this.profile.pendingMobilePhone && String(this.profile.pendingMobilePhone).length) {
      this.phone = String(this.profile.pendingMobilePhone).slice(1);
    }
    if (this.$route.params && this.$route.params.email) {
      this.email = this.$route.params.email
      this.sendEmail()
      this.$route.params.email = ''
    }
  },
  methods: {
    sendEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.$pending && !this.$v.email.$invalid) {
        this.changeEmail();
      }
    },
    sendPhone() {
      this.$v.phone.$touch();
      if (!this.$v.phone.$pending && !this.$v.phone.$invalid) {
        this.changePhone();
      }
    },
    async changeEmail() {
      this.$store.dispatch('showLoader');

      try {
        await api.changeEmail(this.email);
        this.step += 1;
      } catch (e) {
        this.error = e.errors[0].message;
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async changePhone() {
      if (!this.resendAvailable) {
        return;
      } else {
        this.resendAvailable = false;
      }

      this.$store.dispatch('showLoader');

      try {
        await api.changePhone(this.phone);
        await api.sendPhoneConfirmationCode();
        this.step += 1;

        const interval = setInterval(() => {
          this.resendTimeLeft--;
          if (this.resendTimeLeft < 0) {
            this.resendTimeLeft = 30;
            this.resendAvailable = true;
            clearInterval(interval);
          }
        }, 1000);

      } catch (e) {
        this.resendAvailable = true;
        this.error = e.errors[0].message;
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async confirmCode() {
      this.$v.phoneCode.$touch();

      if (!this.$v.phoneCode.$pending && !this.$v.phoneCode.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          // api
          const result = await api.verifyPhoneCode(this.phoneCode);

          if (result) {
            this.step += 1;
            this.error = null;
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    returnToProfile() {
      this.$emit('returnToProfile');
    },
  },
  filters: {
    phoneFilter(val) {
      val = String(val);
      return `+${val[0]} (${val.substr(1,3)}) ${val.substr(4,3)}-${val.substr(7,2)}-${val.substr(9,2)}`
    }
  }
}
</script>
