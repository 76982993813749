<template>
  <my-modal>
    <form v-if="!isSuccess" class="form" @submit.prevent="submit">
      <h2 class="form__title">Смена пароля</h2>
      <div class="form__description form__description_align-center form-row__description">
        Пожалуйста, придумайте пароль, принимая во&nbsp;внимание следующие
        <a href="#" @click="passwordRequirementsShow = !passwordRequirementsShow">требования к&nbsp;паролю</a>
      </div>
      <ul v-if="passwordRequirementsShow" class="form__description">
        <li>минимум 8 символов</li>
        <li>минимум одна буква верхнего регистра</li>
        <li>минимум одна буква нижнего регистра</li>
        <li>минимум одна цифра</li>
        <li>минимум один спец. символ (!@#$%^&*)</li>
      </ul>
      <div class="form-row form-row_no-flex">
        <text-input
          label="Старый пароль*"
          name="oldPassword"
          type="password"
          v-model="$v.form.oldPassword.$model"
          :error="$v.form.oldPassword.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.oldPassword.$error">
              <div v-if="!$v.form.oldPassword.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
      </div>
      <div class="form-row form-row_no-flex">
        <text-input
          label="Новый пароль*"
          name="password"
          type="password"
          v-model="$v.form.password.$model"
          :error="$v.form.password.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.password.$error">
              <div v-if="!$v.form.password.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
      </div>
      <div class="form-row form-row_no-flex">
        <text-input
          label="Повторите новый пароль*"
          name="confirmPassword"
          type="password"
          v-model="$v.form.confirmPassword.$model"
          :error="$v.form.confirmPassword.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.confirmPassword.$error">
              <div v-if="!$v.form.confirmPassword.required">Заполните поле</div>
              <div v-if="$v.form.confirmPassword.required&&!$v.form.confirmPassword.sameAs">Пароли не совпадают</div>
            </div>
          </template>
        </text-input>
      </div>
      <div class="form-row form-row_mobile-no-flex">
        <div class="form__footnote">*Обязательное поле</div>
      </div>
      <div class="form-row form-row_align-center form-row_mobile-wrap">
        <button class="form__submit button button__lg button__blue" type="submit">Изменить</button>
      </div>
      <div class="form-row form-row_align-center">
        <router-link :to="{ path: '/cabinet' }" class="form__link">Назад</router-link>
      </div>
    </form>
    <div v-else>
      <div class="form-row form-row_align-center">
        <div class="forform__description">Пароль успешно изменен</div>
      </div>
      <div class="form-row form-row_align-center">
        <router-link class="form__submit button button__lg button__blue" :to="{ path: '/cabinet' }">Назад</router-link>
      </div>
    </div>
  </my-modal>
</template>

<script>
  import {required, sameAs} from "vuelidate/lib/validators";
  import TextInput from '../TextInput';
  import api from '../../services/api';

  export default {
    name: "ProfilePassword",
    components: { TextInput },
    data() {
      return {
        isSuccess: false,
        form: {
          oldPassword: '',
          password: '',
          confirmPassword: ''
        },
        passwordRequirementsShow: false,
      }
    },
    validations: {
      form: {
        oldPassword: {required},
        password: {required},
        confirmPassword: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
    methods: {
      async submit() {
        this.$v.form.$touch();
        this.isSuccess = false;

        if (!this.$v.form.$pending && !this.$v.form.$invalid) {
          try {
            this.$store.dispatch('showLoader');
            const result = await api.simplePostSuccess('/api/changeMindboxUserPassword', {
              password: this.form.password,
              confirmPassword: this.form.confirmPassword,
              oldPassword: this.form.oldPassword,
            });
            if (result) {
              this.$store.dispatch('hideErrorsBag');
              this.$v.$reset();
              Object.keys(this.form).forEach((key)=>{
                this.form[key] = '';
              });
              this.isSuccess = true;
            }
          } catch (e) {
            this.$store.dispatch('setErrors', e);
          } finally {
            this.$store.dispatch('hideLoader');
          }
        }
      }
    }
  }
</script>
