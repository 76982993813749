<template>
  <div class="reset-password">
    <my-modal>
      <reset-password-step1 v-if="step === 1" @complete="changeStep" />
      <reset-password-step2 v-if="step === 2" @complete="changeStep" :init-login="login" />
      <reset-password-step3 v-if="step === 3" @complete="changeStep" />
      <div v-if="step === 'email'" class="form">
        <h2 class="form__title">Успешно!</h2>
        <div class="form__description form__description_align-center">
          <p>
            На указанную вами электронную почту отправлено письмо. Для продолжения процедуры восстановления пароля, пожалуйста, перейдите по ссылке из письма.
          </p>
        </div>
      </div>
      <div v-if="step === 'final'" class="form">
        <h2 class="form__title">Успешно!</h2>
        <div class="form__description form__description_align-center">
          <p>
            Вы успешно восстановили свой пароль, теперь Вы можете авторизоваться
            на сайте.
          </p>
        </div>
        <div class="reset-password__footer">
          <button
            class="reset-password__submit button button__lg button__blue"
            @click="$store.dispatch('logout')"
          >
            Войти
          </button>
        </div>
      </div>
    </my-modal>
  </div>
</template>

<script>
import MyModal from "./MyModal";
import ResetPasswordStep1 from "./ResetPasswordStep1";
import ResetPasswordStep2 from "./ResetPasswordStep2";
import ResetPasswordStep3 from "./ResetPasswordStep3";
import { parseQueryString, MyError } from "../utils"
import api from "../services/api";

export default {
  name: "ResetPassword",
  components: {
    MyModal,
    ResetPasswordStep1,
    ResetPasswordStep2,
    ResetPasswordStep3,
  },
  props: {
    initStep: { type: Number, default: () => 1 },
  },
  data() {
    return {
      step: this.initStep,
      login: '',
    };
  },
  created() {
    const params = parseQueryString(window.location.search.substring(1));
    if (typeof params['direct-crm-pticket'] !== 'undefined' &&
        typeof params['direct-crm-tticket'] !== 'undefined') {
      this.emailTicketCheck(params);
    }
  },
  methods: {
    changeStep(step, login) {
      this.step = step;
      if (login) {
        this.login = login;
      }
    },
    async emailTicketCheck(params) {
      if (params['direct-crm-pticket'] !== params['direct-crm-tticket'] &&
        await api.simplePostSuccess('/api/ticketAuthorization', { ticket: params['direct-crm-ticket'] })
      ) {
        this.changeStep(3);
      } else {
        throw new MyError('Некорректная ссылка');
      }
    }
  },
};
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

$b: ".reset-password";

#{$b} {
  &__submit {
    margin: 0 auto;

    @include mobile {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  &__links {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    & > li {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:first-child) {
      margin-top: 30px;

      @include mobile {
        margin-top: 20px;
      }
    }
  }

  .my-modal__content {
    width: 100%;
    max-width: 500px;

    .form-captcha {
      gap: 16px;

      @include mobile {
        flex-direction: column;
      }

      .text-input {
        width: 130px;

        @include mobile {
          width: 100%;
        }
      }

      &__image {
        flex: 1 1 auto;

        @include mobile {
          justify-content: flex-start;
          width: 100%;
        }
      }
    }
  }
}
</style>
