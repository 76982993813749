<template>
  <form @submit.prevent="submit">
    <h2 class="form__title">Регистрация</h2>
    <h3 class="form__subtitle">5 шаг.</h3>
    <div class="form-row form-row_no-flex">
      <h3 class="form-row__title">Адрес</h3>

      <dadata-input
        token="44f7453167d491b6b74dec9c95bc71f66de244cf"
        placeholder="Населенный пункт*"
        v-model="$v.form.location.$model"
        :error="$v.form.location.$error"
      >
        <template #errors>
          <div class="dadata-input__error" v-if="$v.form.location.$error">
            <div v-if="!$v.form.location.required">Заполните поле</div>
          </div>
        </template>
      </dadata-input>
    </div>
    <div class="form-row form-row_no-flex">
      <h3 class="form-row__title">Дополнительная информация</h3>
      <p class="form-row__description">Какую марку сигарет Вы покупали и&nbsp;курили чаще всего в&nbsp;течение последних 4-х недель?*</p>
      <custom-select
        label="Выберите марку"
        v-model="$v.form.primaryCigaretteBrand.$model"
        :options="cigaretteMarksOptions"
        :error="$v.form.primaryCigaretteBrand.$error"
      >
        <template #errors>
          <div class="select__error" v-if="$v.form.primaryCigaretteBrand.$error">
            <div v-if="!$v.form.primaryCigaretteBrand.required">Заполните поле</div>
          </div>
        </template>
      </custom-select>
      <custom-select
        v-if="form.primaryCigaretteBrand && getCigaretteSubfamilyOptions(form.primaryCigaretteBrand)"
        label="Выберите тип"
        v-model="form.primaryCigaretteSubfamily"
        :options="getCigaretteSubfamilyOptions(form.primaryCigaretteBrand)"
      ></custom-select>
      <p class="form-row__description">Если Вашей основной марки нет в&nbsp;продаже, что&nbsp;Вы купите?*</p>
      <custom-select
        label="Выберите марку"
        v-model="$v.form.secondaryCigaretteBrand.$model"
        :options="cigaretteMarksOptions"
        :error="$v.form.secondaryCigaretteBrand.$error"
      >
        <template #errors>
          <div class="select__error" v-if="$v.form.secondaryCigaretteBrand.$error">
            <div v-if="!$v.form.secondaryCigaretteBrand.required">Заполните поле</div>
          </div>
        </template>
      </custom-select>
      <custom-select
        v-if="form.secondaryCigaretteBrand && getCigaretteSubfamilyOptions(form.secondaryCigaretteBrand)"
        label="Выберите тип"
        v-model="form.secondaryCigaretteSubfamily"
        :options="getCigaretteSubfamilyOptions(form.secondaryCigaretteBrand)"
      ></custom-select>
    </div>
    <h3 class="form-row__title">Пароль</h3>
    <p class="form-row__description">Пожалуйста, придумайте пароль, принимая во&nbsp;внимание следующие <a href="#" @click.prevent="passwordRequirementsShow = !passwordRequirementsShow">требования к&nbsp;паролю</a></p>
    <ul v-if="passwordRequirementsShow" class="form__description">
      <li>минимум 8 символов</li>
      <li>минимум одна буква верхнего регистра</li>
      <li>минимум одна буква нижнего регистра</li>
      <li>минимум одна цифра</li>
      <li>минимум один спец. символ</li>
    </ul>
    <div class="form-row form-row_2-cols form-row_mobile-no-flex">
      <text-input
        label="Новый пароль*"
        name="password"
        type="password"
        v-model="$v.form.password.$model"
        :error="$v.form.password.$error"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.password.$error">
            <div v-if="!$v.form.password.required">Заполните поле</div>
          </div>
        </template>
      </text-input>
      <text-input
        label="Повторите пароль*"
        name="confirmPassword"
        type="password"
        v-model="$v.form.confirmPassword.$model"
        :error="$v.form.confirmPassword.$error"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.confirmPassword.$error">
            <div v-if="!$v.form.confirmPassword.required">Заполните поле</div>
            <div v-if="!$v.form.confirmPassword.sameAs">Пароли не совпадают</div>
          </div>
        </template>
      </text-input>
    </div>
    <div class="form-row form-row_mobile-wrap">
      <div class="form__nav">
        <router-link v-if="!$store.state.currentUser" :to="{name: 'login'}" class="form__link">Войти</router-link>
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        <delete-questionnaire-btn />
      </div>
      <button class="form__submit button button__lg button__blue" type="submit">Далее</button>
    </div>
  </form>
</template>

<script>
  import {required, sameAs} from "vuelidate/lib/validators";
  import DadataInput from './DadataInput';
  import TextInput from './TextInput';
  import api from '../services/api';
  import CustomSelect from "./CustomSelect";

  export default {
  name: "SignUpStep6",
  components: {
    CustomSelect,
    DadataInput,
    TextInput,
  },
  data() {
    return {
      cigaretteMarks: [],
      form: {
        location: '',
        primaryCigaretteBrand: '',
        primaryCigaretteSubfamily: '',
        secondaryCigaretteBrand: '',
        secondaryCigaretteSubfamily: '',
        password: '',
        confirmPassword: '',
      },
      passwordRequirementsShow: false,
    };
  },
  computed: {
    cigaretteMarksOptions() {
      return [
        ...this.cigaretteMarks.map(item => ({value: item.spicId, name: item.name}))
      ];
    },
    cigaretteSubfamilyOptions() {
      if (this.form.primaryCigaretteBrand) {
        const list = this.cigaretteMarks.filter(item => (item.spicId === this.form.primaryCigaretteBrand))[0];
        if (typeof list.subfamilies !== 'undefined') {
          return [
            ...list.subfamilies.map(item => ({value: item.spicId, name: item.name}))
          ]
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  validations: {
    form: {
      location: {required},
      primaryCigaretteBrand: {required},
      secondaryCigaretteBrand: {required},
      password: {required},
      confirmPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  created() {
    this.fetchCigaretteMarks();
  },
  watch: {
    'form.primaryCigaretteBrand'() {
      this.form.primaryCigaretteSubfamily = '';
    }
  },
  methods: {
    getCigaretteSubfamilyOptions(cigaretteFamily) {
      if (cigaretteFamily) {
        const list = this.cigaretteMarks.filter(item => (item.spicId === cigaretteFamily))[0];
        if (typeof list.subfamilies !== 'undefined') {
          return [
            ...list.subfamilies.map(item => ({value: item.spicId, name: item.name}))
          ]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    async fetchCigaretteMarks() {
      this.cigaretteMarks = await api.fetchCigaretteMarks();
    },
    async submit() {
      this.$v.form.$touch();

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          const result = await api.registerStep6(this.form);

          if (result) {
            this.$emit('complete');
            this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);

        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
  },
}
</script>
