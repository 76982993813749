<template>
  <a class="me-simple-button" :href="url" :class="color">
    <span class="me-simple-button__link" v-html="label" />
  </a>
</template>

<script>
export default {
  name: 'SimpleButton',
  props: {
    url: { type: String, default: '' },
    label: { type: String, default: '' },
    color: { type: String, default: '' },
  }
}
</script>

<style lang="scss">

@import "../../scss/blocks/portal-main";

$b: '.me-simple-button';

#{$b} {
  width: max-content;
  height: max-content;
  padding: 21px 36px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 32px;
  text-decoration: none;

  @include low-desktop {
    padding: 13px 20px;
  }

  &.transparent-white-blue {
    &:hover {
      transition: $transition;
      border-color: $brand-color-new;

      #{$b}__link {
        transition: $transition;
        color: $brand-color-new;
      }
    }
  }

  &__link {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

</style>
