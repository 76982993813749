<template>
  <modal :show.sync="showModal" class="_center">
    <div class="modal-gift">
      <template v-if="first">
        <div class="modal-gift__title">
          Поздравляем!
          <br>
          Вам доступен подарок!
        </div>
        <div class="button button__lg button__blue" @click="first = false">
          Далее
        </div>
      </template>
      <template v-else>
        <div class="modal-gift__title" v-html="gift.title"/>
        <div class="modal-gift__description" v-html="gift.description" />
        <div class="modal-gift__img">
          <img :src="gift.img" :alt="gift.name">
        </div>
        <div class="modal-gift__promo">
          <p>Промо-код</p>
          {{ gift.promoCode }}
        </div>
        <div class="modal-gift__btn button button__lg button__blue" @click="copyPromoCode">
          Скопировать
        </div>
      </template>
    </div>
  </modal>
</template>

<script>

import VueScrollbar from 'vue2-scrollbar';
import { mapState, mapActions } from 'vuex';

export default {
  components: { VueScrollbar },// eslint-disable-line
  name: 'ModalGift',
  data() {
    return {
      first: true,
      showModal: false,
      timeInMs: 5000,
      timeout: null,
    }
  },
  props: {
    gift: { type: Object,
      default: () => {
        return {
          name: '',
          title: '30 дней подписки на онлайн-кинотеатр KION',
          description: 'KION - это онлайн-кинотеатр с оригинальным контентом и большой коллекцией фильмов, сериалов и более 200 телеканалов. Смотрите фильмы и сериалы без ограничений. Отменить подписку можно в любой момент.',
          img: '/frontend/assets/plug/plug-gift.png',
          promoCode: 'g22144',
        }
      }
    }
  },
  computed: {
    ...mapState({
      segment: state => state.userSegments.alpha? 'alpha': state.userSegments.beta ? 'beta' : null,
      idxchechbhfullname: state => state.profileData?.customFields.idxchechbhfullname
    }),

  },
  methods: {
    ...mapActions({
      fetchUserSegments: 'fetchUserSegments'
    }),
    toggleShow() {
      this.showModal = !this.showModal;
    },
    startTimeout() {
      this.timeout = setTimeout(() => {
        this.showModal = true;
        this.removeMouseEventHandlers();
      }, this.timeInMs);
    },
    restartTimeout() {
      clearTimeout(this.timeout);
      // this.startTimeout();
    },
    addMouseEventHandlers() {
      document.addEventListener('mousemove', this.restartTimeout);
      document.addEventListener('keypress', this.restartTimeout);
    },
    removeMouseEventHandlers() {
      document.removeEventListener('mousemove', this.restartTimeout);
      document.removeEventListener('keypress', this.restartTimeout);
    },
    copyPromoCode() {
      try {// eslint-disable-line
        navigator.clipboard.writeText(this.gift.promoCode)
      } catch (e) {
        throw e
      }
    }
  },
  async mounted() {
    if (!this.idxchechbhfullname) {
      await this.fetchUserSegments();
      this.addMouseEventHandlers()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.removeMouseEventHandlers();
  },
}
</script>

<style lang="scss">
@import "../../../scss/base/include";

$b: '.modal-gift';

#{$b} {
  text-align: center;

  &__title {
    color: #1E418E;
    font-size: 26px;
    line-height: 34px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  &__description {
    position: relative;
    color: var(--7-a-8-bb-2, #7A8BB2);
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    padding-top: 30px;
    padding-bottom: 30px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #E8EBF1;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &__promo {
    color: #1E418E;
    font-size: 79.763px;
    line-height: 104.305px;
    text-transform: uppercase;
    margin-bottom: 23px;

    p {
      color: #7A8BB2;
      font-size: 14px;
      line-height: 140%;
      text-transform: capitalize;
    }
  }

  &__img {
    padding: 30px 0;

    img {
      border-radius: 15px;
      width: 100%;
    }
  }

  &__btn {
    cursor: pointer;
  }
}

.my-scrollbar {
  max-height: 200px;
  margin: 0;

  @include tablet {
    max-height: none;
    height: inherit;
  }
}

</style>
