<template>
  <div class="check-input">
    <input
      :id="id"
      class="check-input__field"
      type="checkbox"
      :value="value"
      :checked="value"
      @change="emitChange"
    >
    <label
      class="check-input__label"
      :for="id"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
let checkInputId = 0;

export default {
  name: "CheckInput",
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: () => {
        return 'check-input-' + checkInputId++;
      },
    },
    value: {
      type: [String, Boolean],
      default: null,
    },
  },
  methods: {
    emitChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/base/_settings.scss";
.check-input {
  @include mobile {
    margin-bottom: 15px;
  }
  &__field {
    display: none;

    &:checked + label::before {
      background-color: $brand-color;
      background-clip: content-box;
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &::before {
      content: "";
      width: 17px;
      height: 17px;
      margin-right: 10px;
      box-sizing: border-box;
      padding: 3px;
      border: 1px solid $input-border;
      border-radius: 2px;
    }
  }
}
</style>
