<template>
  <my-modal>
    <div>
      <h2 class="form__title">{{ (deletionStatus || deletionRecall)? 'Восстановление анкеты' : 'Удаление анкеты'}}</h2>
      <template v-if="!deletionConfirm && !deletionRecall">
        <div class="form__description form__description_align-center">
          Удаление персональных данных&nbsp;&mdash; это необратимая операция.
          <br><br>
          После удаления персональные данные не&nbsp;могут быть восстановлены.
          <br><br>
          <span v-if="deletionStatus">Ваша анкета будет удалена {{deletionDate | dateFilter}}</span>
          <span v-else>Ваши данные будут удалены автоматически через 30&nbsp;дней после Вашего подтверждения</span>
        </div>
        <div class="form-row form-row_align-center form-row_mobile-wrap">
          <button class="form__submit button button__lg button__blue" type="submit" @click.prevent="send">{{deletionStatus ? 'Восстановить' : 'Удалить'}}</button>
        </div>
        <div class="form-row form-row_align-center">
          <router-link :to="{ path: '/cabinet' }" class="form__link">Назад</router-link>
        </div>
      </template>
      <template v-if="deletionConfirm">
        <div class="form__description form__description_align-center">
          Вы точно хотите удалить анкету?
        </div>
        <div class="form__description form__description_align-center">
          <a href="#" class="form__link" @click.prevent="deletionApply">Удалить анкету</a>
        </div>
        <div class="form__description_align-center">
          <router-link :to="{ path: '/cabinet' }" class="button button__blue button__lg">Назад</router-link>
        </div>
      </template>
      <template v-if="deletionRecall">
        <div class="form__description form__description_align-center">
          Ваша анкета успешно восстановлена
        </div>
        <div class="form__description_align-center">
          <router-link :to="{ path: '/cabinet' }" class="button button__blue button__lg">Назад</router-link>
        </div>
      </template>
    </div>
  </my-modal>
</template>

<script>
  import api from "../../services/api";

  export default {
    name: "ProfileDelete",
    data() {
      return {
        deletionConfirm: false,
        deletionStatus: false,
        deletionDate: null,
        deletionRecall: false
      }
    },
    mounted() {
      this.deletionStatus = this.$store.state.profileData.customFields.deletionRequestStatus;
      this.deletionDate = this.$store.state.profileData.customFields.deletionRequestDate;
    },
    methods: {
      async deletionApply() {
        this.$store.dispatch("showLoader");
        try {
          const result = await api.simplePostRequest('/api/deletion');
          this.deletionStatus = true;
          this.deletionDate = result.deletionRequestDate;
          this.deletionConfirm = false;
          this.$store.commit('setProfileDeletionStatus', this.deletionStatus);
        } catch (e) {
          this.$store.dispatch('setErrors', e);
        }
        this.$store.dispatch("hideLoader");
      },
      async send() {
        this.$store.dispatch("showLoader");
        if (this.deletionStatus) {
          try {
            await api.simplePostRequest('/api/deletionRecall');
            this.deletionStatus = false;
            this.deletionDate = null;
            this.deletionRecall = true;
            this.$store.commit('setProfileDeletionStatus', this.deletionStatus);
          } catch (e) {
            this.$store.dispatch('setErrors', e);
          }
        } else {
          this.deletionConfirm = true;
        }
        this.$store.dispatch("hideLoader");
      }
    }
  }
</script>
