<template>
  <component
    :is="!theme.active ? 'div' : 'a'"
    :href="actualLink"
    class="me-theme"
    :class="[{'all' : all}, {'disabled': !theme.active}, singleQuantity]"
    :style="{ backgroundImage: `url('${theme.img}')`}"
  >
    <div class="me-theme__info">
      <me-tag
          v-if="theme.link !== '/portal/articles/ny2023/'"
          :text="theme.type === 'Нонограмма' ? theme.type : theme.hashTag"
      />
      <div
          v-if="theme.status"
          class="me-theme__info-status"
          v-html="theme.status"
      />
    </div>
    <span class="me-theme__text" v-html="theme.type === 'Нонограмма' ? theme.title : theme.text" />
  </component>
</template>

<script>
import MeTag from './MeTag';

export default {
  name: 'MeTheme',
  components: { MeTag },
  computed: {
    actualLink() {
      return this.theme.link !== '/portal/articles/ny2023/' ? this.theme.link : '/new-year/ny2023'; // temporary hotfix
    },
  },
  props: {
    theme: Object,
    singleQuantity: { type: String, default: '' },
    all: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
@import '../../scss/base/include';

$b: '.me-theme';

#{$b} {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  width: calc(50% - 30px);
  margin: 0 15px 30px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  user-select: none;
  background-position: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8));
    z-index: 1;
  }

  @include tablet {
    width: 100%;
    margin: 0 0 20px 10px;
    padding: 20px;
  }

  @include mobile {
    padding: 15px;
    margin-left: 0;
    margin-bottom: 15px;
    min-height: 320px;
  }

  &:nth-child(2) {
    margin: 0 0 30px 15px;
    width: calc(50% - 15px);

    @include tablet {
      width: 100%;
      margin: 0 0 20px 10px;
    }

    @include mobile {
      margin: 0 0 15px 0;
    }
  }

  &:nth-child(3),
  &:nth-child(4) {
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  &.bigSquare {
    width: 100%;
    height: 640px;
    padding: 40px;
    font-size: 24px;
    margin: 0 30px 0 0;

    @include tablet {
      padding: 20px;
    }

    @include mobile {
      padding: 15px;
      height: 320px;
    }
  }

  &__text {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $white-true;
    z-index: 2;
  }

  &.all {
    width: calc(25% - 30px);
    margin: 0 30px 30px 0;
    min-height: 320px;

    @include tablet {
      width: calc(50% - 10px);
      margin: 0 10px 20px 0;
    }

    @include mobile {
      width: 100%;
      margin: 0 0 15px 0;
    }

    &:nth-child(2n) {
      @include tablet {
        width: calc(50% - 10px);
        margin: 0 0 20px 10px;
      }

      @include mobile {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }

    &:nth-child(4n) {
      margin: 0 0 30px 0;

      @include tablet {
        width: calc(50% - 10px);
        margin: 0 0 20px 10px;
      }

      @include mobile {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  &.disabled {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #000000;
      opacity: 0.5;
      z-index: 3;
    }
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    z-index: 2;

    &-status {
      position: relative;
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      margin-left: 27px;
      color: #D3D4DF;
    }
  }

  .me-tag {
    margin-bottom: 0;
  }
}
</style>
