<template>
  <div class="sign-input">
    <label>Подпись</label>
    <div class="sign-input__vue-signature-pad">
      <VueSignaturePad
        ref="signaturePad"
        :options="options"
      />
    </div>
    <button
      class="sign-input__clear"
      type="button"
      title="Стереть"
      @click="clear"
    >
      Стереть
    </button>
  </div>
</template>

<script>
export default {
  name: "SignInput",
  props: {
    value: { type: String },
  },
  data() {
    return {
      options: {
        penColor: 'rgba(30, 65, 142, 1)',
        onEnd: this.onEnd,
      },
    };
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$emit('input', '');
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit('input', data);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/base/_settings.scss";

.sign-input {
  position: relative;
  height: 130px;
  padding: 15px 15px 12px;
  border: 1px solid rgba(122, 139, 178, 0.4);
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  @include mobile {
    height: 90px;
  }

  label {
    position: absolute;
  }

  &__vue-signature-pad {
    height: 100%;
  }

  &__clear {
    position: absolute;
    right: 16px;
    bottom: 12px;
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 12px;
    color: $text-light;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    &::before {
      content: "";
      box-sizing: border-box;
      width: 15px;
      height: 15px;
      background: url('#{$abs-assets-path}/assets/i/clear-sign.svg') no-repeat center / cover;
      margin-right: 4px;
    }
  }

  label {
    color: $text-light;
  }
}
</style>
