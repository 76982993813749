<template>
  <div
    class="custom-select"
    :class="{
      '_filled': selected,
      '_invalid': error,
    }"
  >
    <label
      class="custom-select__label"
    >
      {{ label }}
    </label>
    <input
      ref="head"
      type="text"
      class="custom-select__field"
      @click="toggleOpen"
      @focus="checkActive"
      @keydown.prevent.up="optionsUp"
      @keydown.prevent.down="optionsDown"
      @keydown.prevent.enter="optionSelect"
      :value="selected"
      readonly
    >
    <!--<div ref="head" class="custom-select__field" @click="toggleOpen">{{selected}}</div>-->
    <ul ref="body" v-show="opened" class="custom-select__options">
      <li
        v-for="(item, i) in options"
        :key="item.value"
        @click="onSelect(item, i)"
        class="custom-select__options-item"
        :class="{_active: item.name === selected, _hovered: i === currentHighlighted}"
      >{{item.name}}</li>
    </ul>
    <slot name="errors"/>
  </div>
</template>

<script>
  export default {
    name: "CustomSelect",
    props: {
      error: {
        type: [String, Boolean],
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        opened: false,
        selected: null,
        selectedIndex: 0,
        currentHighlighted: -1,
        visibleItems: [0, 5],
        OPTION_HEIGHT: 35,
      }
    },
    mounted() {
      document.addEventListener('click', this.onClick);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.onClick);
    },
    methods: {
      onClick(e) {
        if (this.$el.contains(e.target)) {
          return;
        }
        this.close();
      },
      toggleOpen() {
        const bottomPos = this.$refs.head.getBoundingClientRect().bottom;
        if (this.opened) {
          this.close();
        } else {
          this.opened = true;
        }
        this.$nextTick(() => {
          this.setInitialScroll();
          if (window.innerHeight - bottomPos < 215) {
            this.$refs.body.classList.add('_to-top');
          } else {
            this.$refs.body.classList.remove('_to-top');
          }
        });
      },
      close() {
        this.opened = false;
        this.currentHighlighted = -1;
        this.$refs.head.blur();
      },
      onSelect(item, index) {
        this.selected = item.name;
        this.selectedIndex = index;
        this.$emit('input', item.value);
        this.close();
      },
      optionSelect() {
        if (this.currentHighlighted >= 0 && this.currentHighlighted <= this.options.length - 1) {
          this.onSelect(this.options[this.currentHighlighted], this.currentHighlighted);
        }
      },
      checkActive() {
        this.currentHighlighted = this.options.map(item => item.name).indexOf(this.selected);
      },
      optionsUp() {
        if (this.currentHighlighted <= 0) {
          this.currentHighlighted = this.options.length - 1;
          this.visibleItems = [this.options.length - 6, this.options.length - 1];
          this.$refs.body.scrollTo(0, this.options.length * this.OPTION_HEIGHT);
        } else {
          this.currentHighlighted -= 1;
          if (this.currentHighlighted < this.visibleItems[0]) {
            this.visibleItems = [this.currentHighlighted, this.currentHighlighted + 5];
            this.$refs.body.scrollTo(0, this.currentHighlighted * this.OPTION_HEIGHT);
          }
        }
      },
      optionsDown() {
        if (this.currentHighlighted >= this.options.length - 1) {
          this.currentHighlighted = 0;
          this.visibleItems = [0, 5];
          this.$refs.body.scrollTo(0, 0);
        } else {
          this.currentHighlighted += 1;
          if (this.currentHighlighted > this.visibleItems[1]) {
            this.visibleItems = [this.currentHighlighted - 5, this.currentHighlighted];
            this.$refs.body.scrollTo(0, this.currentHighlighted * this.OPTION_HEIGHT - 180);
          }
        }
      },
      setInitialScroll() {
        this.$refs.body.scrollTo(0, this.selectedIndex * 35);
        this.visibleItems = [this.selectedIndex, this.selectedIndex + 5]
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/_settings.scss";
  .custom-select {
    position: relative;
    height: 54px;
    border: 1px solid $input-border;
    box-sizing: border-box;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &._invalid {
      border-color: $error-border;
    }

    &__options {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 0;
      width: calc(100% + 2px);
      max-height: 215px;
      overflow: auto;
      border: 1px solid $input-border;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0;
      margin: 0 -1px;
      list-style: none;
      background-color: $white-true;
      &-item {
        cursor: pointer;
        padding: 8px 15px;
        font-size: 14px;
        line-height: 1.4;
        &:hover,
        &._active,
        &._hovered {
          background-color: $scroll-track-color;
        }
      }
      &._to-top {
        top: auto;
        bottom: 100%;
      }
    }

    &__label {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      line-height: 1.4;
      color: $text-light;
      pointer-events: none;
      transition: all 0.3s ease;

      ._filled & {
        top: 8px;
        font-size: 11px;
      }
    }

    &__field {
      box-sizing: border-box;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      border: none;
      background: none;
      outline: none;
      color: $brand-color;
      font-size: 14px;
      line-height: 54px;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.90039 1.11328L5.90039 5.11328L9.90039 1.11328' stroke='%231E418E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-position: top 50% right 17px;
      background-repeat: no-repeat;

      &:not([disabled]) {
        cursor: pointer;
      }
    }
  }
</style>
