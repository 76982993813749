<template>
  <div class="me-themes">
    <template v-if="!all">
      <div class="me-themes__primary">
        <me-theme :theme="firstTheme" :single-quantity="singleQuantity" />
      </div>
      <div class="me-themes__secondary" :class="[singleSecondary ? 'single' : '', singleQuantity]">
        <template v-for="(theme, idx) in secondaryThemes">
          <me-theme :key="idx" :theme="theme" />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="me-themes__all">
        <template v-for="(theme, idx) in themes">
          <me-theme :key="idx" :theme="theme" :all="all" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import MeTheme from "./MeTheme";

export default {
  name: 'MeThemes',
  components: { MeTheme },
  props: {
    themes: Array,
    singleQuantity: { type: String, default: 'bigSquare' },
    all: { type: Boolean, default: false },
  },
  data() {
    return {
      singleSecondary: false
    }
  },
  watch: {
    themes(newVal) {
      newVal.length > 2 ? this.singleSecondary = false : this.singleSecondary = true;
    }
  },
  computed: {
    firstTheme() {
      return this.themes[0];
    },
    secondaryThemes() {
      return this.themes.slice(1);
    },
  },
}
</script>

<style lang="scss">
@import "../../scss/base/include";

$b: '.me-themes';

#{$b} {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;

  @include tablet {
    flex-wrap: wrap;
  }

  &__primary {
    margin-right: 15px;
    width: calc(50% - 15px);

    @include tablet {
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    @include mobile {
      flex-wrap: wrap;
      width: 100%;
      height: 320px;
      margin-bottom: 15px;
      margin-right: 0;
    }

    .hRectangle {
      width: 100%;
      height: 305px;
      padding: 40px;
      font-size: 24px;
      margin: 0 30px 0 0;

      @include tablet {
        height: 320px;
        padding: 20px;
      }

      @include mobile {
        padding: 15px;
      }
    }
  }

  &__secondary {
    display: flex;
    flex-wrap: wrap;
    width: 50%;

    @include mobile {
      width: 100%;
      min-height: 320px;
    }

    .me-theme:only-child {
      width: 100%;
      margin-bottom: 0;
    }

    &.hRectangle {
      .me-theme {
        margin-bottom: 0;

        @include tablet-only {
          width: 100%;
          margin-left: 10px;
        }

        &:first-child {
          @include tablet {
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          @include tablet {
            display: none;
          }
        }
      }
    }

    &.single {
      .me-theme {
        width: 100%;
        padding: 40px;
        margin: 0 0 0 15px;

        @include tablet {
          padding: 20px;
          margin: 0 0 0 10px;
        }

        @include mobile {
          padding: 15px;
          margin-left: 0;
        }

        span {
          font-size: 24px;
        }
      }
    }
  }

  &__all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;

    @include tablet {
      margin-left: 0;
    }
  }
}
</style>
