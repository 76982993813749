import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['data', 'options'],
  mounted () {
    this.renderChart(
      {
        labels: false,
        datasets: [
          {
            backgroundColor: ['#3876FF', '#ffffff'],
            data: [ this.options.percentFill, 100 - this.options.percentFill ],
            borderColor: '#3876FF',
            borderWidth: 0.3,
          },
        ],
      },
      this.options
    );
  },
}
