<template>
    <div class="me-slider">
      <div class="me-slider__container">
        <div class="me-slider__info">
          <div class="js-slider-info swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="slide in slides" :key="slide.id" :style="{backgroundImage: `url(${slide.imgUrl})`}">
                <div class="container container-slider _g-relative">
                  <me-tag
                    v-if="slide.topText"
                    :style="{color: slide.topTextColor}"
                    :text="slide.topText"
                  />
                  <div
                      v-if="slide.title"
                      class="me-slider__info--title"
                      :class="{'me-slider__info--title-small': isManyLetters(slide.title)}"
                      v-html="slide.title"
                  ></div>
                  <simple-button
                    :url="slide.url"
                    :label="slide.linkText || 'Подробнее'"
                    :color="'transparent-white-blue'"
                  />
                </div>
              </div>
            </div>
            <div class="container container_portal _g-relative">
              <div class="me-slider__management">
                <div class="swiper-pagination me-slider__pagination" />
                <div class="me-slider__arrows" v-if="slides && slides.length > 1">
                  <div class="me-slider__arrows--left" @click="prev()" />
                  <div class="me-slider__arrows--right" @click="next()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Swiper, {Autoplay, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css'
import axios from "axios";
import SimpleButton from "../ui/SimpleButton";
import MeTag from "../ui/MeTag";

Swiper.use([Pagination, Autoplay]);
  export default {
    name: "MainSlider",
    components: {MeTag, SimpleButton},
    props: {
      sliderSection: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        sliderInfoInstance: null,
        sliderImgInstance: null,
        slides: [{
          id: 0,
          imgUrl: '',
          topTextColor: '',
          topText: '',
          title: '',
          url: '',
          linkText: '',
        }],
      }
    },
    async mounted() {
      this.slides = [];
      await this.getContent();
      await this.init();
    },
    methods: {
      async getContent() {
        this.slides = await axios.post('/api/getBanners', {section: this.sliderSection})
          .then(function (response) {
            if (response.data.status === "success") {
              return response.data.data;
            } else {
              return [];
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      async init() {
        this.sliderInfoInstance = await new Swiper('.js-slider-info', {
          /*autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },*/
          slidesPerView: 1,
          spaceBetween: 0,
          loop: this.slides.length > 1,
          watchOverflow: true,
          pagination: {
            el: '.me-slider__pagination',
            clickable: true,
          }
        });
      },
      next() {
        this.sliderInfoInstance.slideNext();
      },
      prev() {
        this.sliderInfoInstance.slidePrev();
      },
      isManyLetters(text) {
        return text.length >= 65;
      }
    }
  }
</script>

<style lang="scss">
  @import "../../scss/base/include";

  $b: '.me-slider';

  #{$b} {
    .container-slider {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 50px;
      height: 100%;

      @include low-desktop {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__container {
      position: relative;
      display: flex;
    }

    &__info {
      color: $white-true;
      width: 100%;
      .swiper-slide {
        background-color: $black-true;
        padding-top: 418px;
        box-sizing: border-box;
        background-size: cover;
        @media (max-width: 1280px) {
          height: 550px;
          padding-top: 155px;
        }
        @include low-desktop {
          padding: 155px $inner-pd-md 0;
        }
        @include tablet {
          padding: 155px $inner-pd-sm 0;
        }
        @include mobile {
          padding: 90px $inner-pd-xs 0;
          height: 482px;
        }
      }

      &--title {
        max-width: 60%;
        font-weight: 400;
        font-size: 72px;
        line-height: 1.1;
        text-transform: uppercase;
        margin-bottom: 50px;

        @media (max-width: 1280px) {
          font-size: 48px;
          margin-bottom: 40px;
        }

        @include mobile {
          font-size: 32px;
          margin-bottom: 24px;
        }

        @include mobile-small {
          max-width: 400px;
        }

        &-small {
          max-width: 70%;
          font-size: 58px;

          @media (max-width: 1280px) {
            font-size: 38px;
          }

          @include mobile {
            max-width: 90%;
            font-size: 28px;
          }
        }
      }
    }

    &__arrows {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: calc(100% / 3);

      @include mobile {
        display: none;
      }

      &--left,
      &--right {
        position: relative;
        cursor: pointer;
        z-index: 1;
        transition: .3s;
        border: 1px solid $scroll-track-color;
        border-radius: 50%;
        width: 64px;
        height: 64px;

        @include low-desktop {
          width: 40px;
          height: 40px;
        }

        &:hover {
          border-color: $brand-color-new;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 12px;

          @include low-desktop {
            width: 10px;
            height: 10px;
          }
        }
      }

      &--left {
        margin-right: 20px;

        &:before {
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
          border-left: 2px solid $scroll-track-color;
          border-bottom: 2px solid $scroll-track-color;

          &:hover {
            border-bottom-color: $brand-color-new;
          }
        }
      }

      &--right {
        &:before {
          transform: translateX(-50%) translateY(-50%) rotate(-45deg);
          border-right: 2px solid $scroll-track-color;
          border-bottom: 2px solid $scroll-track-color;

          &:hover {
            border-bottom-color: $brand-color-new;
          }
        }
      }
    }

    &__management {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      bottom: 50px;
      left: 0;
    }

    &__pagination {
      position: initial;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: calc(100% / 3);

      .swiper-pagination-bullet {
        cursor: pointer;
        width: 6px;
        height: 6px;
        border: 1px solid $white-true;
        background-color: transparent;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &.swiper-pagination-bullet-active {
          border-color: $white-true;
          background-color: $white-true;
        }
      }
    }

    .swiper-container {
      border-radius: 0 0 30px 30px;
      height: auto;

      @include tablet {
        height: auto;
      }
    }

    .swiper-slide {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(14, 26, 51, 0) 44.25%, #0E1A33 100%);
      }
    }
  }
</style>
