<template>
  <div class="sign-in">
    <my-modal>
      <form class="form" @submit.prevent="submit">
        <h2 class="form__title">
          Добро пожаловать<br> на mevius.ru
        </h2>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Электронная почта или телефон*"
            v-model="$v.form.login.$model"
            name="login"
            :error="$v.form.login.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.form.login.$error">
                <div v-if="!$v.form.login.required">Обязательное поле</div>
                <div v-if="!$v.form.login.phoneOrEmail">Неверный формат электронной почты или телефона</div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Пароль*"
            type="password"
            name="password"
            v-model="$v.form.password.$model"
            :error="$v.form.password.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.form.password.$error">
                <div v-if="!$v.form.password.required">Обязательное поле</div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form-row form-row_no-flex">
          <div class="form-row form-row_mobile-no-flex">
            <check-input
              label="Чужой компьютер"
              name="not-my-device"
              v-model="$v.form.dontRemember.$model"
            />
            <router-link :to="{ name: 'reset' }" class="form__link">Восстановить пароль</router-link>
          </div>
        </div>
        <captcha-input
          ref="captchaInput"
          v-model="$v.form.captcha.$model"
          :error="$v.form.captcha.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.captcha.$error">
              <div v-if="!$v.form.captcha.required">Заполните поле</div>
            </div>
          </template>
        </captcha-input>
        <div class="form__error-alert" v-show="hasError">
          Пожалуйста проверьте правильность заполнения формы
        </div>
        <div class="sign-in__footer">
          <button class="sign-in__submit button button__lg button__blue" type="submit">Войти</button>
          <router-link :to="{name: 'register'}" class="form__link">Зарегистрироваться</router-link>
          <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        </div>
      </form>
    </my-modal>
  </div>
</template>

<script>
import {email, helpers, or, required} from "vuelidate/lib/validators";
import MyModal from './MyModal';
import CheckInput from "./CheckInput";
import TextInput from "./TextInput";
import CaptchaInput from "./CaptchaInput";
// import { parseQueryString, MyError } from "../utils"
// import api from "../services/api";

const phoneRegex = helpers.regex('val1', /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ -]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/);

export default {
  name: "SignIn",
  components: {
    MyModal,
    CheckInput,
    TextInput,
    CaptchaInput,
  },
  data() {
    return {
      hasError: false,
      form: {
        login: '',
        password: '',
        dontRemember: false,
        captcha: {
          sid: '',
          code: '',
        },
      }
    };
  },
  validations: {
    form: {
      login: {
        required,
        phoneOrEmail: or(email, phoneRegex)
      },
      password: {required},
      dontRemember: {},
      captcha: {
        sid: {required},
        code: {required},
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      this.hasError = this.$v.form.$invalid;

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          const result = await this.$store.dispatch('login', this.form);
          if (result) {
            const next = this.$route.query.next;

            if (result.needUpdatePersonalData) {
              return await this.$store.dispatch('goProfileForm');
            }

            if (next) {
              return this.$router.push({path: next.toString()});
            }

            await this.$store.dispatch('segmentsJump', result?.stepRegistration);
            await this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          await this.$store.dispatch('setErrors', e);
          await this.$refs.captchaInput.updateCaptcha();

        } finally {
          await this.$store.dispatch('hideLoader');
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";
.sign-in {
  &__submit {
    margin: 30px auto;
    min-width: 155px;
    @include mobile {
      width: 100%;
      margin: 20px auto;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form__link:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .my-modal__content {
    width: 100%;
    max-width: 470px;

    .form-captcha {
      gap: 16px;

      @include mobile {
        flex-direction: column;
      }

      .text-input {
        width: 130px;

        @include mobile {
          width: 100%;
        }
      }

      &__image {
        flex: 1 1 auto;

        @include mobile {
          justify-content: flex-start;
          width: 100%;
        }
      }
    }
  }
}
</style>
